<template>
  <nav>
    <ul class="pagination"  v-if="showPag">
      <li class="page-item" :class="{disabled: !prevPage}">
        <router-link class="page-link" :to="{query: setPageQuery(prevPage)}">{{voc.previous[lang]}}</router-link>
      </li>

      <li class="page-item" :class="{active: page.active}" v-for="(page, index) in genPaging">
        <router-link class="page-link" :to="{query: setPageQuery(page.val)}">{{page.val}}</router-link>
      </li>

      <li class="page-item" :class="{disabled: !nextPage}">
        <router-link class="page-link" :to="{query: setPageQuery(nextPage)}">{{voc.next[lang]}}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import translations from './translations';

export default {
  name: 'Pagination',
  props: {
    pageCount: Number,
		currentPage: Number,
		lang: String,
  },
  data(){
    return {
      pages:[],
			pagesMaxLength: 5,
			voc: translations,
      showP: false,
    }
  },
  computed: {
    showPag(){
      return this.pageCount>1 ? this.showP=true : this.showP=false
    },
    prevPage(){
      return this.currentPage>0 ? this.currentPage-1 : 0
    },
    nextPage(){
      return (this.currentPage==this.pageCount || this.currentPage==0) ? 0 : this.currentPage+1
    },
    genPaging(){
      this.pages = []
      if(this.pageCount<=this.pagesMaxLength){
        for(let i=0; i<this.pageCount; i++){
          this.pages[i] = {val:i+1, active:false};
        }
      }else if((this.pageCount-this.currentPage)<this.pagesMaxLength-1){
        this.pages[0] = {val:1, active: false};
        let endPages = this.pagesMaxLength-1;
        let i = 1;
        while(endPages){
          this.pages[i] = {val: this.pageCount-endPages+1, active: false}
          i++
          endPages--
        }
      }else{
        if(this.currentPage==1){
          //let fromPage = this.currentPage;
          for(let i=0; i<this.pagesMaxLength-1; i++) {
            this.pages[i] = {val:i+1, active: false};
            //fromPage++;
          }
          this.pages[(this.pagesMaxLength-1)] = {val: this.pageCount, active: false}
        }else{
          this.pages[0] = {val:1, active: false};
          let fromPage = this.currentPage;
          for(let i=1; i<=3; i++) {
            this.pages[i] = {val: fromPage, active: false};
            fromPage++;
          }
          this.pages[(this.pagesMaxLength-1)] = {val: this.pageCount, active: false}
        }
      }

      let i=0;
      while(this.pages[i]) {
        if(this.pages[i].val==this.currentPage) this.pages[i].active = true;
        i++
      }

      return this.pages
    },
  },
  methods: {
    setPageQuery(page){
			let q = {...this.$route.query};
			q.page=page;
			return q;
		},

  }
}
</script>
