<template>
  <div class="products containerBox">
  <h1>{{voc.products[lang]}}</h1>

  <form class='form-inline new_product'>
    <div class='row'>
      <div class="col">
        <input
          v-model='model.name_ru.value'
          name='name_ru'
          type='text'
          class='form-control'
          id='name_ru' :placeholder="voc.nameProductRu[lang]"
          @input.stop="searchAndInput($event)"
        />
      </div>
      <div class="col">
          <input
            v-model='model.name_en.value'
            name='name_en'
            type='text'
            class='form-control'
            id='name_en' :placeholder="voc.nameProductEn[lang]"
            @input.stop="searchAndInput($event)"
          />
      </div>
      <div class="col">
          <input
            v-model="model.details.value"
            name='details'
            type='text'
            class='form-control'
            id='details' :placeholder="voc.details[lang]"
            @input.stop='inputHandler'
          />
      </div>
      <div class="col">
        <select name="category" id='category' class="form-control" @change.stop="searchAndInput($event)">
          <option selected value="default">{{voc.chooseCat[lang]}}</option>
          <option v-for="category in categories" :value="category.id">{{category[lang]}}</option>
        </select>
      </div>
      <div class='col-button'>
        <button @click="saveHandler" :disabled="!isChecked" type="button" class="btn btn-primary">{{voc.saveProduct[lang]}}</button>
      </div>
    </div>
    
  </form>

  <ProductsTable
    :model="productsRequests"
    :tableColumns="tableColumns"
    :lang="lang"
    :currentPage="currentPage"
    :categories="categories"
    @get-after-update="afterUpdateHandler"
  />

  <div class="row">
    <div class="col">
      <Pagination :pageCount="pageCount" :currentPage="currentPage" :lang="lang"/>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import VueAxios from 'vue-axios';
import ProductsTable from './products-table/ProductsTable.vue';
import translations from './translations';
import Pagination from '../pagination/Pagination.vue';

export default {
  name: 'Products',
  components: { ProductsTable, Pagination },
  metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        { vmid: 'description', property: 'description', content: this.voc.description[this.lang] },
        { vmid: 'og:title', property: 'og:title', content: 'Listtok' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
  data(){
    return{
      COLOR:{
        red:'#FFB6C1',
        green:'#B4EEB4'
      },
      categories: [],
      totalCount:0,
			pageCount:0,
			currentPage:0,
			perPage:0,
      model: {
        name_ru: {
          value: '',
          regexp: /^[а-яё\d\w\s%-\\(\\)]{1,256}$/i,
          isChecked: false,
        },
        name_en: {
          value: '',
          regexp: /^[а-яё\d\w\s%-\\(\\)]{1,256}$/,
          isChecked: false,
        },
        details: {
          value: '',
          regexp: /^[а-яё\d\w\s%-\\(\\)]{0,256}$/i,
          isChecked: true,
        },
        category: {
          value: '',
          regexp:/^\d+$/,
          isChecked:false
        },
      },
      productsRequests: [],
      voc: translations,
    }
  },
  computed: {
    lang(){
      return this.$store.getters.lang;
    },
    isChecked(){
      return this.checkCommon()
    },
    tableColumns(){
      return this.voc.tableColumns;
    },
    searchQuery(){
      return {
        name_ru: this.model.name_ru.value,
        name_en: this.model.name_en.value,
        category: this.model.category.value,
      }
    },
  },
  methods: {
    searchAndInput(e){
      this.inputHandler(e);
      this.searchData(this.searchQuery);
    },
    inputHandler(e){
      if(this.model[e.target.name]){
        let el = e.target;
        //this.model[el.name].value=el.value.trim();
        this.model[el.name].value=el.value;

        //console.log(this.model[el.name].value);
        this.checkItem(this.model[el.name], el)
      }
    },
    checkItem(itemModel, elDom){
      let regexp = itemModel.regexp;
      let value = itemModel.value;
      if(regexp.test(value)){
        elDom.style.backgroundColor=this.COLOR.green;
        itemModel.isChecked=true;
      } else {
        elDom.style.backgroundColor=this.COLOR.red;
        itemModel.isChecked=false;
      }
    },
    checkCommon(){
      for(let key in this.model){
        if (key == 'details') continue;
        if (!this.model[key].isChecked)
          return false;
      }
      return true;
    },
    async saveHandler(){
      let formData = new FormData();
      formData.append(document.getElementById('name_ru').name, this.model.details.value ? this.model.name_ru.value.trim() + ' ('+ this.model.details.value.trim() + ')' : this.model.name_ru.value.trim());
      formData.append(document.getElementById('name_en').name, this.model.details.value ? this.model.name_en.value.trim() + ' ('+ this.model.details.value.trim() + ')' : this.model.name_en.value.trim());
      formData.append(document.getElementById('category').name, document.getElementById('category').value);

      axios.post(`${this.$route.path}/save`, formData)
      .then(()=>{
        console.log('data saved');
        this.clearFormHandler();
        this.fetchData(this.$route.query);
      })
    },
    clearFormHandler(){
      let forEach = [].forEach;
      let els = document.querySelectorAll('.form-control');
      forEach.call(els, (item)=>{item.style.backgroundColor=''});

      for(let item in this.model){
        if(item == 'category') {
          this.model[item].isChecked = false;
          document.getElementById('category').value = 'default'
          continue
        }
        this.model[item].value = '';
        document.getElementById(item).value = '';
      }
    },
    fetchData(data={}){
      axios.get('/products/get', {params: data})
      .then(response=>{
        this.productsRequests = response.data;
        console.log(this.productsRequests)
        //console.log(response.headers)
        this.currentPage = +response.headers['x-pagination-current-page'];
        this.totalCount = +response.headers['x-pagination-total-count'];
        this.pageCount = +response.headers['x-pagination-page-count'];
        this.perPage = +response.headers['x-pagination-per-page'];
        //console.log(this.$route.query)
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    searchData(data){
      axios.get('/products/search', {params: data})
      .then(response=>{
        this.productsRequests = response.data;
        console.log(this.productsRequests)
        //console.log(response.headers)
        this.currentPage = +response.headers['x-pagination-current-page'];
        this.totalCount = +response.headers['x-pagination-total-count'];
        this.pageCount = +response.headers['x-pagination-page-count'];
        this.perPage = +response.headers['x-pagination-per-page'];
        //console.log(this.$route.query)
      })
    },
    getCategories(){
      axios.get('/products/get-categories')
        .then(response => {
        this.categories = response.data
      })
    },
    afterUpdateHandler(){
      this.fetchData(this.$route.query);
    },
    // getCategories(){
    //   return new Promise((resolve,reject) => {
    //       axios.get('/products/get-categories')
    //       .then(response => resolve(response))
    //     })
    // },
    // getTest(){
    //   this.getCategories().then(res=>{
    //     return res.data
    //   })
    // },
  },
  created(){
    this.fetchData(this.$route.query);
    this.getCategories()
  },
  beforeRouteUpdate(to, from, next){
		if (this.$route.path == '/products'){
			this.fetchData(to.query);
    }
		next();
	},
}
</script>
