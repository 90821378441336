export default {
  signup: {
    en: 'Signup',
    ru: 'Регистрация',
  },
  title: {
    en: 'Registration in Listtok app',
    ru: 'Регистрация в приложении Listtok'
  },
  description: {
    en: 'To register in the system, fill in all the fields',
    ru: 'Для регистрации в системе заполните все поля'
  },
  ogdescription: {
    en: 'Registration in Online shopping list',
    ru: 'Регистрация в Онлайн списоке покупок'
  },
  email:{
    ru:'Email',
    en:'Email'
  },
  enterEmail:{
    ru:'Введите Email',
    en:'Enter Email'
  },
  nickname: {
    en: 'Nickname (letters, numbers, symbols: - _)',
    ru: 'Имя/никнэйм (буквы, цифры, символы: - _ )'
  },
  enterNickname: {
    en: 'Enter your nickname',
    ru: 'Введите ваш никнэйм'
  },
  password: {
    en: 'Password',
    ru: 'Пароль',
  },
  password2: {
    en: 'Password again',
    ru: 'Пароль ещё раз',
  },
  sixchars: {
    en: 'min 6 simbols',
    ru: 'минимум 6 символов'
  },
  showPassword:{
    ru:'Показать пароль',
    en:'Show password'
  },
  signUping: {
    en: 'Signup',
    ru: 'Зарегистрироваться'
  },
  pass2wrong: {
    en: 'Passwords do not match',
    ru: 'Пароли не совпадают'
  },
  sucReg: {
    en: 'You have successfully registered. Sign in',
    ru: 'Вы успешно зарегистрировались. Выполните Вход'
  },
}
