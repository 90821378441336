export default {
  myContacts: {
    en: "My contacts",
    ru: "Мои контакты"
  },
  title: {
    ru: 'Мои контакты | Listtok',
    en: 'My contacts | Listtok'
  },
  description: {
    en: 'Contact list for sharing lists. Search for new contacts.',
    ru: 'Список контактов для расшаривания списков. Поиск новых контактов.',
  },
  ogdescription: {
    en: 'Contact list',
    ru: 'Список контактов'
  },
  contactList: {
    en: "Contact list:",
    ru: "Список контактов:"
  },
  search: {
    en: "Search",
    ru: "Поиск"
  },
  buttonSearch: {
    en: 'Search',
    ru: 'Искать'
  },
  resultSearch: {
    en: 'Searching results',
    ru: 'Результаты поиска'
  },
  enterNickname: {
    en: 'user nickname or email',
    ru: 'никнейм или email пользователя'
  },
  addContact:{
    en: 'Add contact',
    ru: 'Добавить контакт'
  }
}
