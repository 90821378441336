export default {
  qty: {
    en: 'Qty',
    ru: 'Кол-во'
  },
  edit: {
    en: 'Editing',
    ru: "Редактирование"
  },
  cancel: {
    en: 'Cancel',
    ru: 'Отмена'
  },
  save: {
    en: "Save",
    ru: "Сохранить"
  },
  addLink: {
    en: 'add/change product link',
    ru: 'добав./изм. ссылку на товар'
  },
  link: {
    en: 'Link',
    ru: 'Ссылка'
  },
  linkPlaceholder: {
    en: 'https://...',
    ru: 'https://...'
  },
}
