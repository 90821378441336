export default {
	title: {
		ru: 'Мои списки покупок | Listtok',
		en: 'My shopping lists | Listtok'
	},
	description: {
	   en: 'Personal shopping lists in the Listtok online app. Create your own lists and share them with other users for joint purchases.',
	   ru: 'Личные списки покупок в онлайн приложении Listtok. Создавайте собственные списки и делитесь ими с другими пользователями для совместных покупок.',
	 },
	 ogdescription: {
	   en: 'My shopping list',
	   ru: 'Мои списки покупок'
	 },
	myLists:{
		ru:'Мои списки',
		en:'My lists'
	},
	inputName:{
		ru:'Введите имя списка',
		en:'Enter list name'
	},
	createList:{
		ru:'Создать список',
		en:'Create list'
	},
	tableColumns: {
		name:{
			ru: 'Имя списка',
			en: 'Name list'
		},
		created_at:{
			ru: 'Создан',
			en: 'Created'
		},
		updated_at:{
			ru: 'Обновлен',
			en: 'Updated'
		},
	},
	tabs: {
		active: {
			en: 'Active',
			ru: 'Активные',
			isActive: true,
		},
		finished: {
			en: 'Finished',
			ru: 'Законченые',
			isActive: false,
		},
	},
	noListMessage: {
		en: 'You have no active lists. Create a new one in the input form above, go to it and start adding products!',
		ru: 'У вас нет активных списков. Создайте новый в форме ввода выше, перейдите в него и начинайте добавлять товары!'
	},

}
