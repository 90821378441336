export default {
  addNew: {
    en: "Add new product in vocabulary",
    ru: "Добавить новый товар в словарь"
  },
  cancel: {
    en: 'Cancel',
    ru: 'Отмена'
  },
  save: {
    en: 'Save',
    ru: 'Сохранить'
  },
  name_ru: {
    en: 'Name',
    ru: 'Наименование'
  },
  name_en: {
    en: 'Name',
    ru: 'Наименование'
  },
  cat: {
    en: 'Category',
    ru: 'Категория'
  },
  enterName: {
    en: 'enter name',
    ru: 'введите наименование'
  },
  optional: {
    en: 'optional',
    ru: 'необязательное поле'
  },
  chooseCat: {
    en: 'choose category',
    ru: 'выберите категорию'
  },
  details: {
    en: 'Details (e.g. manufacturer)',
    ru: 'Детали (например, производитель)'
  },
  addLink: {
    en: 'add product link',
    ru: 'добавить ссылку на товар'
  },
  link: {
    en: 'Link',
    ru: 'Ссылка'
  },
  linkPlaceholder: {
    en: 'https://...',
    ru: 'https://...'
  },
}
