<template>
	<div class="login-wrap">
		<div class='login box'>
			<h3>{{voc.h3[lang]}}</h3>
			<p>{{voc.sended[lang]}}</p>
			<p><router-link to='/' class="button" role="button" aria-disabled="true">{{voc.toFront[lang]}}</router-link></p>
		</div>
	</div>
</template>

<script>
import translations from './translations';

export default {
	name: 'ForgetSended',
	data(){
		return {
			voc: translations,
		}
	},
	computed:{
    lang(){
      return this.$store.getters.lang;
    },
	},
}
</script>
