<template>
  <table class='table item-table'>
    <thead class="thead-dark">
      <tr>
        <th scope="row">#</th>
        <th scope="row" v-for="(item, key) in tableColumns">
          <router-link v-if="isSort(key)" :class="sortClass(key)" :to="{query: setSortQuery(key)}">
            {{item[lang]}}
          </router-link>
          <template v-else>
            {{item[lang]}}
          </template>
        </th>
        <th class="checked-th">
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" style="color:green" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
          </svg>
        </th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model" :class="{ completed: item.completed}" :iid="item.id" :i="index">
        <td>{{index+1}}</td>
        <td v-if="lang == 'ru'">
          {{item.name_ru}}
          <a v-if="item.link" :href='item.link' target="_blank" class="link-to-product">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
          </svg>
          </a>
        </td>
        <td v-if="lang == 'en'">
          {{item.name_en}}
          <a v-if="item.link" :href='item.link' target="_blank" class="link-to-product">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
          </svg>
          </a>  
        </td>
        <td>
          {{item.sum +' '+ units[item.unit][lang]}}
          <svg @click.stop="editHandler" v-if="!item.completed" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil edit-button" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>
        </td>
        <td class="time" v-html="getDate(item.added_at)"></td>
        <td>
          <select @change="changeHandler" :id="'sel_'+item.id" class="form-control yesno">
            <option :value="0" v-if="!finished" :selected="item.completed==false">{{voc.not_done[lang]}}</option>
            <option :value="1" :selected="item.completed==true">{{voc.done[lang]}}</option>
          </select>
        </td>
        <td>
          <svg @click.stop="deleteHandler" v-if="!item.completed" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash edit-button-hover" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </td>
      </tr>
    </thead>
  </table>
</template>

<script>
import translations from '../translations';

export default {
  name: 'ItemsTable',
  props: {
    model: Array,
    lang: String,
    tableColumns: Object,
    units: Object,
    finished: Boolean,
  },
  data(){
    return {
      assignColumns: {
        name: {
          sort: true,
        },
        added: {
          sort: true,
        },
      },
      voc: translations,
    }
  },
  computed:{
    items(){
      return this.model;
    },
  },
  methods: {
    getDate(ts){
      //let d = new Date(ts*1000);
      let date = new Date(ts*1000);
      let year = date.getFullYear().toString().substr(-2, 2);
      let month = date.getMonth()+1;
      if (month.toString().length == 1) month = '0'+ month.toString()
      let day = date.getDate();
      if (day.toString().length == 1) day = '0'+ day.toString()
      let h = date.getHours();
      if (h.toString().length == 1) h = '0'+ h.toString()
      let m = date.getMinutes();
      if (m.toString().length == 1) m = '0'+ m.toString()
      return  `${day}.${month}.${year}<br/>${h}:${m}`;
      //return date.toLocaleString();
    },
    deleteHandler(e){
      let targetTr = e.currentTarget.parentNode.parentNode;
      let id = targetTr.getAttribute('iid');
      //console.log(id)
      this.$emit('click-del', {data: id});
    },
    editHandler(e){
      let target = e.currentTarget.parentNode.parentNode;
      let id = target.getAttribute('iid');
      this.$emit('click-edit', {id: id});
    },
    changeHandler(e){
      let target = e.currentTarget.parentNode.parentNode;
      let id = target.getAttribute('iid');
      //let index = target.getAttribute('i');
      // console.log(e.currentTarget.value);
      // console.log(index);
      this.$emit('change-completed', {id: id});
      e.currentTarget.value = e.currentTarget.value == 0 ? 1 : 0;
      // setTimeout(()=>{
      //   //console.log(this.model[0])
      //   if(this.model[index].completed==false && e.currentTarget.value==1)
      //     e.currentTarget.value==0;
      // }, 3000)
    },
    isSort(key){
      return ((key in this.assignColumns) && this.assignColumns[key].sort) ? true : false;
    },
    setSortQuery(fieldName){
        let {...q}=this.$route.query;
        if(fieldName == 'name') fieldName = fieldName+'_'+this.lang
        if(fieldName == 'added') fieldName = fieldName+'_at'
        q.sort = q.sort==fieldName ? `-${fieldName}` : fieldName;
        return q;
    },
    sortClass(fieldName){
        if(fieldName == 'name') fieldName = fieldName+'_'+this.lang
        if(fieldName == 'added') fieldName = fieldName+'_at'
        return this.$route.query.sort==`-${fieldName}` ? {desc:true} : this.$route.query.sort==fieldName ? {asc:true} : {}
    },
  },
}
</script>

<style>
a.desc::after {
  content: url(../../../assets/arrow-thick-top.svg);
}
a.asc::after {
  content: url(../../../assets/arrow-thick-bottom.svg);
}
</style>