<template>
  <div class='users containerBox'>
    <h1>{{voc.h1[lang]}}</h1>

    <form class='new_list'>
      <div class="new_list_half">
        <div class="input-group">
          <input
            name='username'
            type='text'
            class='form-control'
            id='inputUser'
            @input.stop="searchAndInput($event)"
          />
        </div>
      </div>
    </form>

    <div class="scroll-table">
      <table class="table products-table">
        <thead class="thead-dark">
          <tr>
            <th scope="row">id</th>
            <th scope="row" v-for="(item, key) in tableColumns">
              {{item[lang]}}
            </th>
          </tr>
        </thead>
        <tr v-for="(user, index) in users" :iid="user.id">
          <td>{{user.id}}</td>
          <td>{{user.username}}</td>
          <td class="time">{{ getDate(user.created_at) }}</td>
          <td class="time">{{ getDate(user.updated_at) }}</td>
        </tr>
      </table>
    </div>

    <div class="row">
      <div class="col">
        <Pagination :pageCount="pageCount" :currentPage="currentPage" :lang="lang"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Pagination from '../pagination/Pagination.vue';
import translations from './translations';


export default {
  name: 'User',
  components: { Pagination },
  metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        { vmid: 'description', property: 'description', content: this.voc.description[this.lang] },
        { vmid: 'og:title', property: 'og:title', content: 'Listtok' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
  data(){
    return {
      users: [],
      totalCount:0,
			pageCount:0,
			currentPage:0,
			perPage:0,
      voc: translations,
    }
  },
  computed: {
    lang(){
      return this.$store.getters.lang;
    },
    tableColumns(){
      return this.voc.tableColumns;
    },
  },
  methods: {
    searchAndInput(e){
      //console.log(e.target.value)
      this.fetchData({username: e.target.value});
    },
    fetchData(data={}){
    axios.get('/user/get-all', {params: data})
      .then(response=>{
        this.users = response.data;
        //console.log(this.users)
        //console.log(response.headers)
        this.currentPage = +response.headers['x-pagination-current-page'];
        this.totalCount = +response.headers['x-pagination-total-count'];
        this.pageCount = +response.headers['x-pagination-page-count'];
        this.perPage = +response.headers['x-pagination-per-page'];
        //console.log(this.$route.query)
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    getDate(ts){
      //let d = new Date(ts*1000);
      let date = new Date(ts*1000);
      let year = date.getFullYear().toString().substr(-2, 2);
      let month = date.getMonth()+1;
      if (month.toString().length == 1) month = '0'+ month.toString()
      let day = date.getDate();
      if (day.toString().length == 1) day = '0'+ day.toString()
      let h = date.getHours();
      if (h.toString().length == 1) h = '0'+ h.toString()
      let m = date.getMinutes();
      if (m.toString().length == 1) m = '0'+ m.toString()
      return  day + "." + month + "." + year + " " + h + ":" + m;
      //return date.toLocaleString();
    },
  },
  created(){
    this.fetchData(this.$route.query);
  },
  beforeRouteUpdate(to, from, next){
		if (this.$route.path == '/users'){
			this.fetchData(to.query);
    }
		next();
  }
}
</script>