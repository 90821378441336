<template>
  <div class="login box">
    <h1>{{voc.h1[lang]}}</h1>
    <small id="feedbackHelp" class="form-text text-muted">{{voc.smallText[lang]}}</small>
    <p></p>
    <form @submit.prevent="onSubmit" @input="inputHandler">
    <div class="form-group">
				<label for="inputEmail">{{voc.yourEmail[lang]}}</label>
				<input
					name="email"
					type="text"
					class="form-control"
					id="inputEmail" :placeholder="voc.enterEmail[lang]"
				/>
		</div>
    <div class="form-group">
				<label for="inputQuestion">{{voc.yourQuestion[lang]}}</label>
				<textarea
					name="question"
					class="form-control"
          rows='5'
					id="inputQuestion" :placeholder="voc.enterQuestion[lang]"
				/>
		</div>
    <button @click="sendQuestion" type="button" class="button" :class="{ hoverDel: !isChecked }" :disabled="!isChecked">{{voc.send[lang]}}</button>
    </form>
  </div>
</template>

<script>
import translations from './translations';
import axios from 'axios'

export default {
  name: "Feedback",
  metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        { name: 'description', content: this.voc.description[this.lang] },
        { name: 'keywords', content: 'Listok, online shopping list, онлайн список покупок, покупки онлайн, список покупок, онлайн приложение, online app, обратная связь, feedback'},
        { vmid: 'og:title', property: 'og:title', content: 'Listok - обратная связь' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
  data(){
    return {
      model: {
        email:{
          value:'',
          regexp:/^[\w\.]{4,20}@{1}\w+\.\w+$/,
          isChecked:false,
        },
        question:{
          value: '',
          regexp:/^(.){1,1024}$/,
          isChecked:false
        }
      },
      FeedbackForm: {
        email: '',
        question: ''
      },
      voc: translations,
    }
  },
  computed:{
    lang(){
      return this.$store.getters.lang;
    },
    isChecked(){
        for (let key in this.model)
        {
          if (!this.model[key].isChecked)
            return false;
        }
        return true;
      },
	},
  methods: {
    setValue(el, field, value)
      {
        const COLOR={
          red:'#FFB6C1',
          green:'#B4EEB4'
        };

        if ( this.model[field].regexp.test(value) )
        {
          this.model[field].value = value;
          this.model[field].isChecked = true;
          el.style.backgroundColor=COLOR.green;
        }
        else{
          this.model[field].isChecked = false;
          el.style.backgroundColor=COLOR.red;
        }

      },

      inputHandler(e){
        let el = e.target;

        if (this.model[el.name] && this.model[el.name].regexp)
        {
          this.setValue(el, el.name, el.value);
        }

      },

    sendQuestion(){
      this.FeedbackForm.email = this.model.email.value;
      this.FeedbackForm.question = this.model.question.value;

      axios.put('/feedback/add', this.FeedbackForm)
      .then(()=>{
        console.log('question sended')
        this.$router.push('/')
        this.$store.dispatch('setMessage',
          {data: `${this.voc.dataSended[this.lang]}`,
          color: 'green'}
        );
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    }
  },
}
</script>
