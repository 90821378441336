<template>
	<div class="settings containerBox">
  	<h1>{{voc.h1[lang]}}</h1>
    
      <div class='settings-line'>
        <div class="left_set">{{voc.name[lang]}}</div>
        <div class="right_set">{{username}}</div>
      </div>
      <div class='settings-line'>
        <div v-if='showEmail'>
          <div class="left_set">{{voc.email[lang]}}</div>
          <div class="right_set">{{email}}</div>
          <div class="right_set"><a class='change-set' @click="showEmailHandler">{{voc.change[lang]}}</a></div>
        </div>
        <div v-if='showEmailForm' @input="inputEmailHandler">
          <div>
            <div class='left_set'></div>
            <div class='right_set'><a class='change-set' @click='showEmailHandler'>{{voc.cancel[lang]}}</a></div>
          </div>
          <div>
            <div class="left_set">{{voc.newEmail[lang]}}</div>
            <div class="right_set">
              <input 
                type="text"
                class="form-control"
                id='new-email'
                name='new_email'
              />
            </div>
          </div>
          <div>
            <div class="left_set">{{voc.password[lang]}}</div>
            <div class="right_set">
              <input 
                type="password"
                class="form-control"
                id='password-email'
                name='password_email'
              />
            </div>
          </div>
          <div>
            <div class='left_set'></div>
            <div class='right_set'>
              <button type="submit" class="btn btn-primary" :disabled="!isCheckedEmailForm" @click="saveEmailHandler">{{voc.save[lang]}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class='settings-line'>
        <div class="left_set">{{voc.created[lang]}}</div>
        <div class="right_set">{{createdAt}}</div>
      </div>
      <div class='settings-line'>
        <div v-if="showPass">
          <div class="left_set">{{voc.password[lang]}}</div>
          <div class='right_set'><a class='change-set' @click='showPassHandler'>{{voc.change[lang]}}</a></div>
        </div>
        <div v-if="showPassForm" @input="inputHandler">
          <div>
            <div class='left_set'></div>
            <div class='right_set'><a class='change-set' @click='showPassHandler'>{{voc.cancel[lang]}}</a></div>
          </div>
          <div>
            <div class='left_set'>{{voc.oldPass[lang]}}</div>
            <div class='right_set'>
              <input 
                type="password"
                class="form-control"
                id='old-pass'
                name='password_hash_old'
              />
              </div>
          </div>
          <div>
            <div class='left_set'>{{voc.newPass[lang]}}</div>
            <div class='right_set'>
              <input
                type="password"
                class="form-control"
                id='new-pass'
                name='password_hash'
                :placeholder="voc.sixchars[lang]"
              />
              </div>
          </div>
          <div>
            <div class='left_set'>{{voc.repeatPass[lang]}}</div>
            <div class='right_set'>
              <input
                type="password"
                class="form-control"
                id='repeat-pass'
                name='password_hash_repeat'
              />
              </div>
          </div>
          <div>
            <div class='left_set'></div>
            <div class='right_set'><p class='warn' v-if="showWrong">{{voc.pass2wrong[lang]}}</p></div>
          </div>
          <div>
            <div class='left_set'></div>
            <div class='right_set'>
              <button type="submit" class="btn btn-primary" :disabled="!isChecked" @click="saveHandler">{{voc.save[lang]}}</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import translations from './translations';

export default {
  name: 'Settings',
  metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        { vmid: 'description', property: 'description', content: this.voc.description[this.lang] },
        { vmid: 'og:title', property: 'og:title', content: 'Listtok' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
  data() {
    return {
      model_email: {
        new_email:{
          value: '',
          regexp: /^[\w\.]{4,20}@{1}\w+\.\w+$/,
          isChecked:false,
        },
        password_email: {
          value:'',
          regexp:/^.{6,20}$/,
          isChecked:false,
        }
      },
      model: {
        password_hash_old:{
          value:'',
          regexp:/^.{6,20}$/,
          isChecked:false,
        },
        password_hash:{
          value:'',
          regexp:/^.{6,20}$/,
          isChecked:false,
        },
        password_hash_repeat:{
          value:'',
          regexp:/^.{6,20}$/,
          isChecked:false,
        },
      },
      username: '',
      email: '',
      createdAt: '',
      showEmail: true,
      showEmailForm: false,
      showPass: true,
      showPassForm: false,
      showWrong: false,
      EmailFrom: {
        password: '',
        new_email: ''
      },
      PassForm: {
        oldPassword: '',
        newPassword: '',
        retypePassword: '',
      },
     	voc: translations,
    }
  },
	computed: {
    lang(){
      return this.$store.getters.lang;
    },
    isChecked(){
      for (let key in this.model)
      {
        if (!this.model[key].isChecked)
          return false;
      }
      if(this.model.password_hash_repeat.value){
        if(this.model.password_hash.value != this.model.password_hash_repeat.value) return false;
      }
      return true;
    },
    isCheckedEmailForm(){
      for (let key in this.model_email)
      {
        if (!this.model_email[key].isChecked)
          return false;
      }
      return true;
    },
	},
  methods: {
    setValue(el, field, value)
    {
      const COLOR={
        red:'#FFB6C1',
        green:'#B4EEB4'
      };

      if ( this.model[field].regexp.test(value) )
      {
        this.model[field].value = value;
        this.model[field].isChecked = true;
        el.style.backgroundColor=COLOR.green;

        if(field=='password_hash_repeat' && this.model[field].value != this.model['password_hash'].value){
            this.model[field].isChecked = false;
            el.style.backgroundColor=COLOR.red;
            this.showWrong = true;
          }else this.showWrong = false;
      }
      else{
        this.model[field].isChecked = false;
        el.style.backgroundColor=COLOR.red;
      }
    },
    inputHandler(e){
      let el = e.target;

      if (this.model[el.name] && this.model[el.name].regexp)
      {
        this.setValue(el, el.name, el.value);
        //console.log(this.model)
      }
    },
    setValueEmailForm(el, field, value)
    {
      const COLOR={
        red:'#FFB6C1',
        green:'#B4EEB4'
      };

      if ( this.model_email[field].regexp.test(value) )
      {
        this.model_email[field].value = value;
        this.model_email[field].isChecked = true;
        el.style.backgroundColor=COLOR.green;
      }
      else{
        this.model_email[field].isChecked = false;
        el.style.backgroundColor=COLOR.red;
      }
    },
    inputEmailHandler(e){
      let el = e.target;

      if (this.model_email[el.name] && this.model_email[el.name].regexp)
      {
        this.setValueEmailForm(el, el.name, el.value);
        //console.log(this.model)
      }
    },
    getSettings(){
      axios.get('/get-settings')
      .then(response => {
        this.username = response.data.username
        this.email = response.data.email
        this.createdAt = (new Date(response.data.created_at*1000)).toLocaleString()
      })
    },
    showEmailHandler(){
      this.showEmail = !this.showEmail
      this.showEmailForm = !this.showEmailForm
    },
    showPassHandler(){
      this.showPass = !this.showPass
      this.showPassForm = !this.showPassForm
    },
    saveHandler(){
      this.PassForm.oldPassword = this.model.password_hash_old.value;
      this.PassForm.newPassword = this.model.password_hash.value;
      this.PassForm.retypePassword = this.model.password_hash_repeat.value;

      axios.put('/settings/change-pass', this.PassForm)
      .then(response => {
          this.$store.dispatch('getMenu');
          this.showPassHandler();
          this.$store.dispatch('setMessage',
            {data: `${this.voc.passChanged[this.lang]}`,
            color: 'green'}
          );
        })
        .catch(e => {
          this.$store.dispatch('setError', e);
          return;
        });
    },
    saveEmailHandler(){
      this.EmailFrom.password = this.model_email.password_email.value;
      this.EmailFrom.new_email = this.model_email.new_email.value;

      axios.put('/settings/change-email', this.EmailFrom)
      .then(response => {
          this.showEmailHandler();
          this.getSettings();
          this.$store.dispatch('setMessage',
            {data: `${this.voc.emailChanged[this.lang]}`,
            color: 'green'}
          );
      })
      .catch(e => {
          this.$store.dispatch('setError', e);
          return;
        });
    }
  },
  created(){
    this.getSettings();
  }
}
</script>
