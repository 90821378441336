<template>
  <div class='list-items containerBox'>
    <h1>{{voc.h1[lang]}} <span class='name_list'>{{this.nameList}}</span></h1>

    <div class="alert alert-primary" role="alert" v-if="isFinished">
      {{voc.ended[lang]}}
    </div>

    <div class='share' v-if="userRole != 'roleGuest'">
      <svg @click='toggleShare()' xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
        <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
      </svg>
      <div class="toggle_share" v-if='isActiveShare' @click='toggleShare()'>
      <a :href="`https://t.me/share/url?url=https://listtok.ru${this.$route.path}?ac=${this.accessNumber}&text=${voc.textLink[lang]}`">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
        </svg>
      </a>
      <a :href="`whatsapp://send?text=https://listtok.ru${this.$route.path}?ac=${this.accessNumber}`" data-action="share/whatsapp/share">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
          <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" style="color:green"/>
        </svg>
      </a>
      </div>
    </div>

    <div class='list-users'>
      <span>{{voc.users[lang]}}: </span>
      <span v-for="item in listUsers" class='uitem'>
        {{item.username}}
        <span v-if="item.owner==true" class="uaitem">
          ({{voc.admin[lang]}})
        </span>
      </span>

      <div class='adduser' v-if="!isFinished && userRole != 'roleGuest' && listUsersId.indexOf(userId)!=-1"><a href='#' @click.prevent="showAddUserHandler">{{voc.adduser[lang]}}</a></div>
      <div class='adduser' v-if="userRole != 'roleGuest' && this.accessNumber==this.accessLink && listUsersId.indexOf(userId)==-1">
        <button type="button" class="btn btn-primary" @click='addUserListHandler(userId)'>{{voc.joinToList[lang]}}</button>
      </div>

      <transition name="fade">
      <div class='row form-add-user' v-if="showAddUser">
        <div class='col'>
          <select v-model="selectedUser" name="user" id='user' class="form-control">
            <option disabled selected>{{voc.chooseUser[lang]}}</option>
            <option v-for="item in userToAdd" :value="item.id">{{item.username}}</option>
          </select>
        </div>
        <div class='col'>
          <button type="button" class="btn btn-primary" @click="addUserListHandler(selectedUser)" :disabled='!selectedUser'>{{voc.add[lang]}}</button>
        </div>
      </div>
      </transition>

    </div>

    <div v-if="!isFinished" class='find-product'>
    <div class='form-row serch-field'>
      <div class="form-group col-md-6 no-margin">
        <vAuto
          v-model="model.name_product.value"
          :result="products"
          :aname="'name_product'"
          :aclass="'form-control'"
          :aid="'id-product'" :aph="voc.nameProduct[lang]"
          @v-search="searchHandler"
          @v-choice="addItem"
          @v-clear="removeButton"
        />
        <div v-if="addButton" class="addButton">
          <button @click="modalAddHandler" type="button" class="btn btn-secondary button-prod">{{voc.addNew[lang]}}</button>
        </div>
      </div>
      <div class='form-group col-auto no-margin'>
          <select name="category" v-model='model.category.value' id='category' class="form-control filter-prod">
            <option value=''>{{voc.chooseCat[lang]}}</option>
            <option v-for="category in categories" :value="category.id">{{category[lang]}}</option>
          </select>
        </div>      
    </div>
    </div>

    <ItemsTable
      :model="listItems"
      :lang="lang"
      :tableColumns="tableColumns"
      :units="units"
      :finished='isFinished'
      @click-del='delHandler'
      @click-edit='modalHandler'
      @change-completed='changeHandler'
    />

    <Modal v-if="showModal"
      :item="editItem"
      :lang="lang"
      :units="units"
      @close="modalHandler"
      @save="saveHandler"
    />

    <ModalAdd v-if="showModalAdd"
      :lang="lang"
      :name="nameProductAdd"
      @close-add="modalAddHandler"
      @new-added="lastAddedHandler"
    />
  </div>
</template>

<script>
import axios from 'axios';
import VueAxios from 'vue-axios';
import lodash from 'lodash';
import translations from './translations';
import vAuto from '../autocomplete/v-autocomplete';
import ItemsTable from './items-table/ItemsTable';
import itemSum from './item-sum/itemSum';
import Modal from './modal/Modal';
import ModalAdd from './modal-add/ModalAdd';

export default {
  name: 'ListItems',
  components: { vAuto, ItemsTable, itemSum, Modal, ModalAdd },
  props: {
    accessLink: String,
  },
  metaInfo() {
    return {
      title: this.voc.title[this.lang] + ' ' + this.nameList,
      meta: [
        { vmid: 'description', property: 'description', content: this.voc.description[this.lang] },
        { vmid: 'og:title', property: 'og:title', content: 'Listtok' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
  data(){
    return{
      COLOR:{
        red:'#f1c9c9',
      },
      showModal: false,
      showModalAdd: false,
      nameProductAdd: '',
      showAddUser: false,
      editItem: {},
      nameList: '',
      accessNumber: '',
      isActiveShare: false,
      categories: {},
      units: {},
      products: [],
      listItems: [],
      addButton: false,
      contacts: [],
      sum: 1,
      listUsers: [],
      listUsersId: [],
      selectedUser: '',
      hackListUsers: [],
      timeout: null,
      model: {
        name_product: {
          value: '',
          regexp: /^[a-zа-яё\d\s]{1,256}$/i,
          isChecked: false,
        },
        category: {
          value: '',
          regexp:/^\d+$/,
          isChecked:false
        },
      },
      voc: translations,
    }
  },
  watch: {
    products(val){
      let viewButton = 0;
      let langProp = "name_"+this.lang;
      for(let i=0; i < val.length; i++){
        if (val[i][langProp].toLowerCase() == document.getElementById('id-product').value.toLowerCase().trim()) viewButton++
      }
      (viewButton > 0 && val.length > 0) ? this.addButton = false : this.addButton = true
      // if(val.length > 0){
      //   this.addButton = false
      // }else this.addButton = true
    },
    isFinished(val){
      if (val==true) this.setCompleted();
    },
  },
  computed: {
    lang(){
      return this.$store.getters.lang;
    },
    tableColumns(){
      return this.voc.tableColumns;
    },
    searchQuery(){
      return {
        category: this.model.category.value,
      }
    },
    userToAdd(){
      var hackListUsers = _.cloneDeep(this.listUsers);
      hackListUsers.forEach(function(value){
        delete value['owner']
      })

      const uniq = []
      this.contacts.forEach(n => {
        if (hackListUsers.every(m => m.id !== n.id || m.username !== n.username)) {
          uniq.push(n);
        }
      });
      return uniq
    },
    isFinished(){
      if(this.listItems.every(e => e.completed == true) && this.listItems.length !=0){
        //console.log(true);
        return true;
      } else {
        //console.log(false);
        return false;
      }
    },
    userRole() {
      return this.$store.getters.userRole;
      //return this.$store.state.entry.userRole;
    },
    userId() {
      return this.$store.getters.userId;
    },
  },
  methods: {
    searchAndInput(e){
      this.inputHandler(e);
      if(this.searchQuery) this.getProducts(this.searchQuery);
      //console.log(this.products);
    },
    inputHandler(e){
      if(this.model[e.target.name]){
        let el = e.target;
        this.model[el.name].value=el.value.trim();
        //console.log(this.model[el.name].value);
        //this.checkItem(this.model[el.name], el)
      }
    },
    getListName(data={id:this.$route.params.listId, access:this.accessLink}) {
      axios.get('/lists/get-name', {params: data})
      .then(response=>{
        //console.log(response.data)
        this.nameList = response.data;
      })
    },
    getAccessNumber(){
      axios.get('/lists/get-access-number', {params: {id: this.$route.params.listId, access: this.accessLink}})
      .then(response=>{
        //console.log(response.data)
        this.accessNumber = response.data;
      })
    },
    toggleShare(){
      this.getAccessNumber();
      this.isActiveShare = !this.isActiveShare;
    },
    getCategories(){
        axios.get('/products/get-categories')
        .then(response => {
          this.categories = response.data
        })
    },
    getUnits(){
        axios.get('/products/get-units')
        .then(response => {
          this.units = response.data
        })
    },
    getProducts(data){
      this.timeout = setTimeout(()=>{
        axios.get('/products/search-autocomplete', {params: data})
        .then(response => {
          this.products = response.data
        })
        .catch(e=>{
          this.$store.dispatch('setError', e);
        });
      }, 200)
    },
    searchHandler(e){
      clearTimeout(this.timeout)
      //console.log(e.data)
      this.searchQuery.name_product = e.data.trim()
      if(e.data == '') {
        this.setChooseCat()
        return
      }
      this.getProducts(this.searchQuery);
      console.log(this.products)
    },
    addItem(e){
      //this.toAddItems.push(e.data)
      console.log(e.data)
      this.addToList(e.data)
    },
    lastAddedHandler(e){
      axios.get('/products/prod-by-id')
      .then((response)=>{
        //this.toAddItems.push(response.data)
        let toAddItem = response.data
        toAddItem.link = e.link
        this.addToList(toAddItem)
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      });
    },
    addToList(item){
      for(let i=0; i<this.listItems.length; i++){
        if(item.id == this.listItems[i].id_product){
          let exist;
          this.lang == 'ru' ? exist = item.name_ru : exist = item.name_en;
          this.$store.dispatch('setMessage',
            {data: `${exist.toUpperCase()} ${this.voc.alreadyAdded[this.lang]}.`,
            color: 'red'}
          );
          return;
        }
      }

      item.sum = 1;
      item.unit = 1;
      item.listId = Number.parseInt(this.$route.params.listId);

      axios.post(`${this.$route.path}/save`, {item: item})
      .then(() => {
        console.log('data saved');
        //this.toAddItems = [];
        this.getlistItems(this.$route.query);
        this.$store.dispatch('setMessage',
          {data: `${this.voc.dataAdded[this.lang]}`,
          color: 'green'}
        );
      })
    },
    delHandler(e){
      axios.post(`${this.$route.path}/delete`, {id: e.data, listId: this.$route.params.listId})
      .then(()=>{
        console.log('item deleted')
        this.getlistItems();
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      });
    },
    delItemToAddHandler(e){
      let targetTr = e.currentTarget.parentNode.parentNode;
      let id = targetTr.getAttribute('akey');
      console.log(id)
      this.toAddItems.splice(id, 1);
    },
    setChooseCat(){
      let select = document.getElementById('category');
      let selectedOption = document.getElementById('category').selectedIndex;

      select.options[selectedOption].selected = false;
      this.model.category.value = '';
      select.options[0].selected = true;
    },
    getlistItems(data={}){
      data.access = this.accessLink;
      axios.get(`/list/${this.$route.params.listId}`, {params: data})
      .then(response => {
        this.listItems = response.data;
        //console.log(this.listItems)
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      });
    },
    modalHandler(e){
      if(e) {this.getItemById(e.id).then(response=>{
        this.editItem = response.data;
        this.editItem.iid = e.id;
        this.showModal = !this.showModal;
      })}
      else this.showModal = !this.showModal;
      //console.log(e.id)
    },
    getItemById(id){
      return new Promise((resolve, reject)=>{
        axios.get('/list-items/get-item', {params: {
                                            id: id
                                          }
        }).then(response=>resolve(response))
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      });
    },
    saveHandler(e){
      //console.log(e)
      axios.get('/list-items/edit-item', {params: {
                                            id: e.iid,
                                            sum: e.sum,
                                            unit: e.unit,
                                            listId: this.$route.params.listId,
                                            link: e.link,
                                          }
      }).then(()=>{
        this.showModal = !this.showModal;
        this.getlistItems()
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    changeHandler(e){
      axios.put('/list-items/change-completed', {id: e.id, listId: this.$route.params.listId, access: this.accessLink})
      .then(()=>{
        this.getlistItems(this.$route.query)
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    removeButton(){
      this.addButton = false;
    },
    modalAddHandler(e){
      this.showModalAdd = !this.showModalAdd;
      this.nameProductAdd = document.getElementById('id-product').value;
    },
    getListUsers(){
      axios.get('/list-user/get', {params: {id: this.$route.params.listId, access: this.accessLink}})
      .then(response=>{
        this.listUsers = response.data;
        console.log(this.listUsers)

        for(let i=0; i<this.listUsers.length; i++){
          this.listUsersId.push(this.listUsers[i].id)
        }
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    addUserListHandler(user){
      axios.get('/list-user/add', {params: {list_id: this.$route.params.listId, user_id: user, access: this.accessLink}})
      .then(()=>{
        this.getListUsers();
        if(user != this.userId) this.showAddUserHandler();
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    getContacts(){
      axios.get('/contact/list')
      .then(response=>{
        this.contacts = response.data;
        console.log(this.contacts)
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    showAddUserHandler(){
      this.showAddUser = !this.showAddUser;
    },
    setCompleted(){
      axios.get('/lists/set-completed', {params: {id: this.$route.params.listId}})
      .then(()=>console.log('this list completed'))
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
  },
  created() {
    this.getListName()
    this.getlistItems()
    this.getCategories()
    this.getUnits()
    this.getListUsers()
    this.getContacts();
    //this.listItems = _debounce(this.getlistItems, 500);
    setInterval(()=>this.getlistItems(this.$route.query), 10000)
  },
  mounted() {
    if(this.userRole != 'roleGrues' && this.accessLink) this.getAccessNumber()
  },
  beforeRouteUpdate(to, from, next){
		this.getlistItems(to.query);
		next();
	},
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
