<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container add-modal">

          <div class="modal-header">
            <slot name="header">
                {{voc.addNew[lang]}}
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <form>
                <div class="form-group" v-if="lang == 'ru'">
                  <label for="name_ru">{{voc.name_ru[lang]}}: <span class='required'>*</span></label>
                  <input @input.stop='inputHandler' v-model="model.name_ru.value" type="text" class="form-control" id="name_ru" name="name_ru" :placeholder="voc.enterName[lang]">
                </div>

                <div class="form-group" v-if="lang == 'en'">
                  <label for="name_en">{{voc.name_en[lang]}}:</label>
                  <input @input.stop='inputHandler' v-model="model.name_en.value" type="text" class="form-control" id="name_en" name='name_en' :placeholder="voc.enterName[lang]">
                </div>

                <div class="form-group">
                  <label for="details">{{voc.details[lang]}}:</label>
                  <input @input.stop='inputHandler' type="text" class="form-control" id="details" name="details" :placeholder="voc.optional[lang]">
                </div>

                <div class="form-group">
                  <label for="category">{{voc.cat[lang]}}: <span class='required'>*</span></label>
                  <select name="category" id='category' class="form-control" @change.stop="inputHandler">
                    <option disabled selected>{{voc.chooseCat[lang]}}</option>
                    <option v-for="category in categories" :value="category.id">{{category[lang]}}</option>
                  </select>
                </div>

                <div class="form-group">
                  <div class="add-link">
                    <a href='#' @click.prevent="showAddLinkHandler">{{voc.addLink[lang]}}</a>
                  </div>
                  <div class="form-group" v-if="showLinkAdd">
                    <label for="link">{{voc.link[lang]}}:</label>
                    <input @input.stop='inputHandler' type="text" class="form-control" id="link" name="link" :placeholder="voc.linkPlaceholder[lang]">
                  </div>
                </div>
              </form>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-primary" @click="closeHandler">
                {{voc.cancel[lang]}}
              </button>
              <button class="btn btn-success" :disabled="!isChecked" @click="saveHandler">
                {{voc.save[lang]}}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import VueAxios from 'vue-axios';
import translations from './translations';

export default {
  name: 'ModalAdd',
  props:{
    lang: String,
    name: String,
  },
  data(){
    return {
      COLOR:{
        red:'#FFB6C1',
        green:'#B4EEB4'
      },
      model: {
        name_ru: {
          value: '',
          regexp: /^[а-яё\d\w\s%-\\(\\)]{1,256}$/i,
          isChecked: false,
        },
        name_en: {
          value: '',
          regexp: /^[а-яё\d\w\s%-\\(\\)]{1,256}$/,
          isChecked: false,
        },
        details: {
          value: '',
          regexp: /^[а-яё\d\w\s%-\\(\\)]{0,256}$/i,
          isChecked: true,
        },
        category: {
          value: '',
          regexp:/^\d+$/,
          isChecked:false
        },
        link: {
          value: null,
          regexp: /^(ftp|http|https):\/\/[^ "]+$/i,
          isChecked: true,
        },
      },
      categories: {},
      showLinkAdd: false,
      voc: translations,
    }
  },
  computed: {
    isChecked(){
      return this.checkCommon()
    },
  },
  watch: {
    name: function(val){
      let name = "name_"+this.lang
      this.model[name].value = val
    }
  },
  methods: {
    inputHandler(e){
      if(this.model[e.target.name]){
        let el = e.target;
        this.model[el.name].value=el.value;
        //console.log(this.model[el.name].value);
        this.checkItem(this.model[el.name], el)
      }
    },
    checkItem(itemModel, elDom){
      let regexp = itemModel.regexp;
      let value = itemModel.value;
      if(regexp.test(value)){
        elDom.style.backgroundColor=this.COLOR.green;
        itemModel.isChecked=true;
      } else {
        elDom.style.backgroundColor=this.COLOR.red;
        itemModel.isChecked=false;
      }
    },
    checkCommon(){
      this.lang == 'ru' ? delete this.model.name_en : delete this.model.name_ru
      for(let key in this.model){
        if (key == 'details' || key == 'link') continue;
        if (!this.model[key].isChecked)
          return false;
      }
      return true;
    },
    async saveHandler(){
      let link = this.model.link.value == '' ? null : this.model.link.value;
      let formData = new FormData();
      if(this.lang == 'ru'){
        var productWithDetails = this.model.details.value ? this.model.name_ru.value.toLowerCase().trim() + ' ('+ this.model.details.value + ')' : this.model.name_ru.value.toLowerCase().trim()
        formData.append(document.getElementById('name_ru').name, productWithDetails)
      }else {
        var productWithDetails = this.model.details.value ? this.model.name_en.value.toLowerCase().trim() + ' ('+ this.model.details.value + ')' : this.model.name_en.value.toLowerCase().trim()
        formData.append(document.getElementById('name_en').name, productWithDetails)
      }
      formData.append(document.getElementById('category').name, this.model.category.value);

      axios.post(`/products/save`, formData)
      .then(()=>{
        console.log('data saved');
        //this.clearFormHandler();
        this.$emit('new-added', {link: link});
        this.closeHandler();
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
      //console.log(this.model.category.value);
    },
    closeHandler(){
      this.$emit('close-add')
    },
    getCategories(){
        axios.get('/products/get-categories')
        .then(response => {
          this.categories = response.data
        })
    },
    showAddLinkHandler(){
      this.showLinkAdd = !this.showLinkAdd;
    },
  },
  created(){
    this.getCategories();
  },
  mounted(){
    if(this.lang=='ru'){
      this.model.name_ru.value=this.name.trim();
      this.checkItem(this.model.name_ru, document.getElementById('name_ru'))
    }else{
        this.model.name_en.value=this.name.trim();
      this.checkItem(this.model.name_en, document.getElementById('name_en'))
    }
  },
}
</script>
