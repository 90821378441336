export default {
  previous: {
    ru: "Пред.",
    en: "Previous"
  },
  next: {
    ru: "След.",
    en: "Next"
  },
}
