export default {
  h1: {
    ru: 'Мой словарь',
    en: 'My vocabulary'
  },
  title: {
    en: 'My products | Listtok',
    ru: 'Мои товары | Listtok'
  },
  description: {
    en: 'My product list',
    ru: 'Список моих товаров'
  },
  ogdescription: {
    en: 'Product list',
    ru: 'Список товаров'
  },
  nameProduct: {
    en: 'Product name',
    ru: 'Наименование товара'
  },
  details: {
    en: 'Details (optional)',
    ru: 'Детали (необяз.)',
  },
  saveProduct: {
    en: "Add Product",
    ru: 'Добавить товар'
  },
  chooseCat: {
    en: 'choose category',
    ru: 'выберите категорию'
  },
  tableColumns: {
    name: {
      en: 'Name',
      ru: 'Наименование'
    },
    category: {
      en: 'Category',
      ru: 'Категория'
    },
  },
}