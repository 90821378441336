export default {
    title: {
      ru: 'Настройки | Listtok',
      en: 'Settings | Listtok'
    },
    description: {
      en: 'Settings accaunt in web app Listtok',
      ru: 'Настройки аккаунта в веб приложении Listtok'
    },
    ogdescription: {
      en: 'Settings accaunt in Online shopping list Listtok',
      ru: 'Настройки аккаунта в Онлайн списоке покупок Listtok'
    },
    h1: {
      ru: 'Настройки',
      en: 'Settings'
    },
    name: {
      ru: 'Имя/Никнейм',
      en: 'Name/Nickname'
    },
    email: {
      en: 'Email',
      ru: 'Email'
    },
    created: {
      en: 'Registration date',
      ru: 'Дата регистрации'
    },
    password: {
      en: 'Password',
      ru: 'Пароль'
    },
    change: {
      en: 'change',
      ru: 'изменить'
    },
    cancel: {
      en: 'cancel',
      ru: 'отмена'
    },
    oldPass: {
      en: 'Old password',
      ru: 'Старый пароль'
    },
    newPass: {
      en: 'New password',
      ru: 'Новый пароль'
    },
    repeatPass: {
      en: 'Repeat password',
      ru: 'Повторите пароль'
    },
    save: {
      en: 'Save',
      ru: 'Сохранить'
    },
    pass2wrong: {
      en: 'Passwords do not match',
      ru: 'Пароли не совпадают'
    },
    sixchars: {
      en: 'min 6 simbols',
      ru: 'мин 6 символов'
    },
    passChanged: {
      en: 'Password changed successfully',
      ru: 'Пароль успешно сменен'
    },
    emailChanged: {
      en: 'Email changed successfully',
      ru: 'Email успешно сменен'
    },
    newEmail: {
      en: 'New email',
      ru: 'Новый email'
    },
}