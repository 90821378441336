export default {
  title: {
    en: 'Feedback | Listtok',
    ru: 'Обратная связь | Listtok'
  },
  description: {
    en: 'Leave your wishes or questions about the application.',
    ru: 'Оставте ваши пожелания либо вопросы по работе приложения. Мы стремимся стать лучше и будем рады учесть ваше мнение!'
  },
  ogdescription: {
    en: 'Questions and wishes regarding the Listtok application',
    ru: 'Вопросы и пожелания по работе приложения Listtok'
  },
  h1: {
    en: 'Feedback',
    ru: 'Обратная связь'
  },
  smallText: {
    en: 'Ask a question you are interested in or leave a reward regarding the work of the Listtok online application. We will definitely answer you!',
    ru: 'Задайте интересующий вас вопрос или же оставьте пожалание, касаемое работы онлайн приложения Listtok. Мы вам обязательно ответим!'
  },
  yourEmail: {
    ru: 'Ваш Email',
    en: 'Your Email'
  },
  enterEmail: {
    ru: 'Введите Email',
    en: 'Enter Email'
  },
  yourQuestion: {
    ru: 'Ваш вопрос или предложение',
    en: 'Your question or suggestion'
  },
  enterQuestion: {
    ru: 'Введите вопрос или предложение',
    en: 'Enter a question or suggestion'
  },
  send: {
    en: 'Send',
    ru: 'Отправить'
  },
  dataSended: {
    en: 'The data was sent successfully',
    ru: 'Данные были успешно отправлены'
  },
}