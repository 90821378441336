<template>
	<div class="login-wrap">
		<div class="login box">
			<h1>{{voc.h1[lang]}}</h1>
			<div class="form-group">
				<label for="exampleInputEmail1">{{voc.email[lang]}}</label>
				<input v-model="email" type="email" class="form-control" id="email-field" name="email" aria-describedby="email" :placeholder="voc.enterEmail[lang]">
				<small id="emailHelp" class="form-text text-muted">{{voc.smallText[lang]}}</small>
			</div>
  			<button @click="sendMail" type="button" :disabled="this.email == ''" class="btn btn-primary mb-2">{{voc.send[lang]}}</button>
		</div>
	</div>
</template>

<script>
import translations from './translations';
import axios from 'axios';

export default {
	name: 'Forget',
	metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        { name: 'description', content: this.voc.description[this.lang] },
        { name: 'keywords', content: 'Listok, online shopping list, онлайн список покупок, покупки онлайн, список покупок, онлайн приложение, online app, восстановление пароля, password recovery'},
        { vmid: 'og:title', property: 'og:title', content: 'Listok - восстановление пароля' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
	data(){
		return {
			email: '',
			voc: translations,
		}
	},
	computed:{
    lang(){
      return this.$store.getters.lang;
    },
	},
	methods:{
		sendMail(){
			let formData = new FormData();
			formData.append(document.getElementById('email-field').name, this.email);

			axios.post(`/forget-send-mail`, formData)
			.then(()=>{
        console.log('email sended');
        this.email = '';
        this.$router.push('/forget-sended');
      })
			.catch(e=>{
        this.$store.dispatch('setError', e);
      })
		}
	},
}
</script>
