<template>
  <table class="table my-lists">
    <thead class="thead-dark">
      <tr>
        <th scope="row">#</th>
        <th scope="row" v-for="(item, key) in tableColumns">
          <router-link v-if="isSort(key)" :class="sortClass(key)" :to="{query: setSortQuery(key)}">
            {{item[lang]}}
          </router-link>
          <template v-else>
            {{item[lang]}}
          </template>
        </th>
      </tr>
    </thead>
    <tr v-for="(list, index) in model" :key="list.id" @click="goList(list.id)">
      <td>{{ currentPage*10-10+(index+1)}}</td>
      <td>{{list.name}}</td>
      <td class="time">{{ getDate(list.created_at) }}</td>
      <td class="time">{{ getDate(list.updated_at) }}</td>
    </tr>
  </table>
</template>

<script>


export default{
  name: 'ListTable',
  props: {
    model: Array,
    tableColumns: Object,
    assignColumns: Object,
    lang: String,
    currentPage: Number,
  },
  data(){
    return{
    }
  },
  methods:{
    // getDate(ts){
    //   //let d = new Date(ts*1000);
    //   let date = new Date(ts*1000);
    //   let year = date.getFullYear();
    //   let month = date.getMonth();
    //   let day = date.getDate();
    //   let h = date.getHours();
    //   let m = date.getMinutes();
    //   //return  day + " " + month + " " + year + " " + h + ":" + m;
    //   return date.toLocaleString();
    // },
    getDate(ts){
      //let d = new Date(ts*1000);
      let date = new Date(ts*1000);
      let year = date.getFullYear().toString().substr(-2, 2);
      let month = date.getMonth()+1;
      if (month.toString().length == 1) month = '0'+ month.toString()
      let day = date.getDate();
      if (day.toString().length == 1) day = '0'+ day.toString()
      let h = date.getHours();
      if (h.toString().length == 1) h = '0'+ h.toString()
      let m = date.getMinutes();
      if (m.toString().length == 1) m = '0'+ m.toString()
      return  day + "." + month + "." + year + " " + h + ":" + m;
      //return date.toLocaleString();
    },
    isSort(key){
      return ((key in this.assignColumns) && this.assignColumns[key].sort) ? true : false;
    },
    setSortQuery(fieldName){
        let {...q}=this.$route.query;
        q.sort = q.sort==fieldName ? `-${fieldName}` : fieldName;
        return q;
    },
    sortClass(fieldName){
        return this.$route.query.sort==`-${fieldName}` ? {desc:true} : this.$route.query.sort==fieldName ? {asc:true} : {}
    },
    goList(id){
      //this.$route.push({path: `/lists/${id}`})
      this.$router.push(`/lists/${id}`)
    },
  },
}
</script>

<style>
a.desc::after {
  content: url(../../../assets/arrow-thick-top.svg);
}
a.asc::after {
  content: url(../../../assets/arrow-thick-bottom.svg);
}
</style>
