<template>
	<div class="login">
		<h2>{{voc.h1[lang]}}</h2>
		<small id="changeHelp" class="form-text text-muted">{{voc.smallText[lang]}}</small>
		<p></p>
		<form @submit.prevent="onSubmit" @input="inputHandler">
			<div class="form-group">
				<label for="inputPassword1">{{voc.password[lang]}}  ({{voc.sixchars[lang]}})</label>
				<input
					name="password_hash"
					:type="[!showPassword ? 'password' : 'text']"
					class="form-control"
					id="inputPassword1" :placeholder="voc.password[lang]"
				/>
			</div>

      <div class="form-group">
				<label for="inputPassword2">{{voc.password2[lang]}}</label>
				<input
					name="password_hash_repeat"
					:type="[!showPassword ? 'password' : 'text']"
					class="form-control"
					id="inputPassword2" :placeholder="voc.password[lang]"
				/>
        <p class='warn' v-if="showWrong">{{voc.pass2wrong[lang]}}</p>
			</div>

			<div class="form-group custom-control custom-checkbox">
				<input
					v-model="showPassword"
					type="checkbox" class="custom-control-input" id="showPassword">
				<label class="custom-control-label" for="showPassword">{{voc.showPassword[lang]}}</label>
			</div>

			<button type="submit" class="btn btn-primary" :disabled="!isChecked">{{voc.change[lang]}}</button>
			</form>
	</div>
</template>

<script>
import translations from './translations';
import axios from 'axios';

export default {
	name: 'ChangePass',
	props: {
    token: String,
  },
  metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        { name: 'description', content: this.voc.description[this.lang] },
        { name: 'keywords', content: 'Listtok, online shopping list, онлайн список покупок, покупки онлайн, список покупок, онлайн приложение, online app, установка нового пароля, set new password'},
        { vmid: 'og:title', property: 'og:title', content: 'Listtok - установка нового пароля' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
	data(){
		return {
			model: {
				password_hash:{
          value:'',
          regexp:/^.{6,20}$/,
          isChecked:false,
        },
        password_hash_repeat:{
          value:'',
          regexp:/^.{6,20}$/,
          isChecked:false,
        },
			},
      ForgetForm: {
        password: '',
        retypePassword: '',
        token: '',
      },
			showPassword: false,
      showWrong: false,
			voc: translations,
		}
	},
	computed:{
    lang(){
      return this.$store.getters.lang;
    },
		isChecked(){
      for (let key in this.model)
      {
        if (!this.model[key].isChecked)
          return false;
      }
      if(this.model.password_hash_repeat.value){
        if(this.model.password_hash.value != this.model.password_hash_repeat.value) return false;
      }
      return true;
    },
	},
	methods:{
		setValue(el, field, value)
    {
      const COLOR={
        red:'#FFB6C1',
        green:'#B4EEB4'
      };

      if ( this.model[field].regexp.test(value) )
      {
        this.model[field].value = value;
        this.model[field].isChecked = true;
        el.style.backgroundColor=COLOR.green;

        if(field=='password_hash_repeat' && this.model[field].value != this.model['password_hash'].value){
            this.model[field].isChecked = false;
            el.style.backgroundColor=COLOR.red;
            this.showWrong = true;
          }else this.showWrong = false;
      }
      else{
        this.model[field].isChecked = false;
        el.style.backgroundColor=COLOR.red;
      }
    },
		inputHandler(e){
      let el = e.target;

      if (this.model[el.name] && this.model[el.name].regexp)
      {
        this.setValue(el, el.name, el.value);
        //console.log(this.model)
      }
    },
    onSubmit(){
      this.ForgetForm.password = this.model.password_hash.value;
      this.ForgetForm.retypePassword = this.model.password_hash_repeat.value;
      this.ForgetForm.token = this.token;
      axios.put('/forget-change-pass', this.ForgetForm)
      .then(()=>{
        console.log('pass changed')
        this.$router.push('/login')
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
	},
}
</script>
