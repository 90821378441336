export default {
		h1: {
			ru: 'Список:',
			en: 'List'
		},
		title: {
	    en: 'Listtok | list - ',
	    ru: 'Listtok список: '
	  },
	  description: {
	    en: 'List of products in this list. Adding new products to the list.',
	    ru: 'Список товаров этого списка. Добавление новый товаров в список.',
	  },
	  ogdescription: {
	    en: 'List of products',
	    ru: 'Список товаров'
	  },
		nameProduct: {
	    en: 'Start to enter the product',
	    ru: 'Начните вводить товар'
	  },
		chooseCat: {
	    en: 'category filter',
	    ru: 'фильтр категория'
	  },
		tableColumns: {
	    name: {
	      en: 'Name',
	      ru: 'Название'
	    },
			sum: {
				en: 'Sum',
				ru: 'Кол-во'
			},
	    added: {
	      en: 'Time',
	      ru: 'Время'
	    },
			// completed: {
			// 	en: 'Completed',
			// 	ru: "Выполнено"
			// },
	  },
		tableAdd: {
			name: {
	      en: 'Name',
	      ru: 'Название'
	    },
			category: {
				en: "Category",
				ru: 'Категория'
			},
			sum: {
				en: 'Sum',
				ru: 'Кол-во'
			},
		},
		add: {
			en: 'Add',
			ru: 'Добавить'
		},
		listToAdd: {
			en: 'Tentative list',
			ru: 'Предварительный список'
		},
		listToAddClarification: {
			en: 'Indicate the quantity and units. rev. Next, add the items to your main shopping list',
			ru: 'Укажите кол-во и ед. изм. Далее добавьте товары в основной список покупок'
		},
		dataAdded: {
			en: 'Data added',
			ru: 'Данные добавлены'
		},
		alreadyAdded: {
			en: 'have already been added. Edit this position in the list if necessary.',
			ru: 'уже добавлено. При необходимости отредактируйте эту позицию в списке.'
		},
		done: {
			en: 'yes',
			ru: 'да'
		},
		not_done: {
			en: 'no',
			ru: 'нет'
		},
		addNew: {
			en: 'Add',
			ru: 'Добавить'
		},
		users: {
			en: 'Members',
			ru: 'Участники'
		},
		admin: {
			en: 'admin',
			ru: 'админ'
		},
		adduser: {
			en: 'Add user',
			ru: 'Добавить участника'
		},
		chooseUser: {
			en: 'Choose User',
			ru: 'Выберите пользователя'
		},
		ended: {
			en: "This list is complete",
			ru: "Этот список завершен"
		},
		textLink: {
			en: "Private link to list",
			ru: "Приватная ссылка на список"
		},
		addedToVocabulary: {
			en: "Added to DICTIONARY and PRELIMINARY LIST",
			ru: "Добавлено в СЛОВАРЬ и ПРЕДВАРИТЕЛЬНЫЙ СПИСОК"
		},
		opt: {
			en: 'opt.',
			ru: 'необяз.'
		},
		joinToList: {
			en: 'Join',
			ru: 'Присоединиться'
		},
}
