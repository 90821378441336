export default {
  title: {
    en: 'Set new password | Listtok',
    ru: 'Установка нового пароля | Listtok'
  },
  description: {
    en: 'Восстановление доступа к аккаунту в приложении Listtok',
    ru: 'Restoring access to an account in the Listtok app',
  },
  ogdescription: {
    en: 'Set new password',
    ru: 'Установка нового пароля'
  },
	h1: {
		en: 'Access recovery',
		ru: 'Восстановление доступа'
	},
	smallText: {
		en: 'To restore access, set a new password',
		ru: 'Для восстановления доступа установите новый пароль'
	},
	password: {
    en: 'Password',
    ru: 'Пароль',
  },
  password2: {
    en: 'Password again',
    ru: 'Пароль ещё раз',
  },
  sixchars: {
    en: 'min 6 simbols',
    ru: 'минимум 6 символов'
  },
	pass2wrong: {
    en: 'Passwords do not match',
    ru: 'Пароли не совпадают'
  },
	showPassword:{
    ru:'Показать пароль',
    en:'Show password'
  },
	change: {
		en: 'Change',
		ru: 'Сменить'
	},
}