<template>
  <div class="lists containerBox">
    <h1>{{voc.myLists[lang]}}</h1>
    
    <form class='new_list'>
      <div class="new_list_half">
        <div class="input-group">
          <input
            name='name'
            type='text'
            class='form-control'
            id='inputName' :placeholder="voc.inputName[lang]"
            @input.stop="inputHandler($event)"
            @keypress.enter.prevent="saveHandler()"
          />

          <button @click="saveHandler" :disabled="!model.name.isChecked" type="button" class="btn btn-success new_list_button">{{voc.createList[lang]}}</button>
        </div>
      </div>
    </form>
    
    <router-view/>

    <div id="dynamic-component-demo" class="demo">
      <button
        v-for="tab in voc.tabs"
        v-bind:ikey="tab[lang]"
        v-bind:class="['tab-button', { active: currentTab === tab[lang] }]"
        v-on:click="isActive = tab.isActive, isActiveHandler()"
      >
        {{ tab[lang] }}
      </button>
    </div>

    <ListTable
      :model="listRequests"
      :assignColumns="assignColumns"
      :tableColumns="tableColumns"
      :lang="lang"
      :currentPage="currentPage"
    />

    <div class="alert alert-warning" role="alert" :class="showHideMessage">
      {{voc.noListMessage[lang]}}
    </div>

    <div class="row">
			<div class="col">
				<Pagination :pageCount="pageCount" :currentPage="currentPage" :lang="lang"/>
			</div>
		</div>
  </div>
</template>

<script>
import axios from 'axios';
import VueAxios from 'vue-axios';
import translations from './translations';
import ListTable from './list-table/ListTable.vue';
import Pagination from '../pagination/Pagination.vue'

export default {
  name: 'Lists',
  components: { ListTable, Pagination },
  metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        { vmid: 'description', property: 'description', content: this.voc.description[this.lang] },
        { vmid: 'og:title', property: 'og:title', content: 'Listtok' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
  data(){
    return {
      COLOR:{
        red:'#FFB6C1',
        green:'#B4EEB4'
      },
      totalCount:0,
			pageCount:0,
			currentPage:0,
			perPage:0,
      model: {
        name: {
          value: '',
          regexp: /^[а-я\W\d\w\s]{1,256}$/i,
          isChecked: false,
        }
      },
      assignColumns: {
        name: {
          sort: true,
        },
        created_at: {
          sort: true,
        },
        updated_at: {
          sort: true,
        },
      },
      listRequests: [],
      voc: translations,
      isActive: true,
      showHideMessage: {
        show: false,
        hide: true
      }
    }
  },
  computed: {
    lang(){
      return this.$store.getters.lang;
    },
    tableColumns(){
      return this.voc.tableColumns;
    },
    currentTab(){
      return this.isActive == 1 ? this.voc.tabs.active[this.lang] : this.voc.tabs.finished[this.lang]
    },
  },
  methods: {
    goAdd: function(e){
      this.$router.push({path: 'lists/create'})
    },
    async saveHandler(){
      let formData = new FormData();
      formData.append(document.getElementById('inputName').name, document.getElementById('inputName').value);

      axios.post(`${this.$route.path}/save`, formData)
      .then(()=>{
        console.log('data saved');
        this.clearFormHandler();
        this.fetchData(this.$route.query);
      })
    },
    inputHandler(e){
      if(this.model[e.target.name]){
        let el = e.target;
        this.model[el.name].value=el.value.trim();
        console.log(this.model[el.name].value);
        this.checkItem(this.model[el.name], el)

      }
    },
    checkItem(itemModel, elDom){
      let regexp = itemModel.regexp;
      let value = itemModel.value;
      if(regexp.test(value)){
        elDom.style.backgroundColor=this.COLOR.green;
        itemModel.isChecked=true;
      } else {
        elDom.style.backgroundColor=this.COLOR.red;
        itemModel.isChecked=false;
      }
    },
    clearFormHandler(){
      let forEach = [].forEach;
      let els = document.querySelectorAll('.form-control');
      forEach.call(els, (item)=>{item.style.backgroundColor=''});

      this.model.name.value = '';
      document.getElementById('inputName').value = '';
    },
    fetchData(data={}){
      data.isActive = this.isActive;
      axios.get('/lists/get', {params: data})
      .then(response=>{
        this.listRequests = response.data;
        console.log(this.listRequests)
        console.log(response.headers)
        this.currentPage = +response.headers['x-pagination-current-page'];
        this.totalCount = +response.headers['x-pagination-total-count'];
        this.pageCount = +response.headers['x-pagination-page-count'];
        this.perPage = +response.headers['x-pagination-per-page'];
        //console.log(this.$route.query)
        this.showHideHandler()
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    isActiveHandler(){
      this.fetchData()
    },
    showHideHandler(){
      this.showHideMessage.hide = (this.isActive && this.listRequests.length>0) || (!this.isActive) ? true : false;
      this.showHideMessage.show = this.isActive && this.listRequests.length==0 ? true :false
    }
    /*toDateString(arr){
      for(let i=0; i < arr.length; i++){
        let ts = new Date(arr.i.created_at * 1000);
        if(arr.i.created_at instanceof Number) arr.i.created_at = ts.getFullYear();
      }
    },*/
  },
  created(){
    this.fetchData(this.$route.query);
  },
  beforeRouteUpdate(to, from, next){
		if (this.$route.path == '/lists'){
			this.fetchData(to.query);
    }
		next();
	},
}
</script>
