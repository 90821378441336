<template>
  <div class="help">
    <h1>Помощь</h1>
    <p>
      В данном разделе рассмотрены основные концепции приложения Listtok. Также представлена инструкция по использованию и ответы на общие вопросы.
    </p>
    <ul class="table-contents">
      <li><a href="#basic-concepts">Основные концепции</a></li>
      <li><a href="#user-manual">Инструкция пользователя</a></li>
    </ul>

    <h2 id="basic-concepts">Основные концепции</h2>
    <p>
      <b>Термины и определения:</b><br/>
      <span class='help-red'>Список</span> - список, состоящий из товаров или иных позиций (предметов,вещей, дел, фильмов и т.д.), которые вы можете добавлять в него.<br/>
      <span class='help-red'>Совместный писок</span> - список, в состав которого входит более одного участника.<br/>
      <span class='help-red'>Товар</span> - единица, представляющая часть списка, из которых он состоит (например, продукт, дело, жаелание, фильм и т.д.).<br/>
      <span class='help-red'>Системный словарь</span> - товары, которые уже есть в системе и доступные для всех пользователей для их поиска и добавления в списки.<br/>
      <span class='help-red'>Личный словарь</span> - товары, добавленные вами в процессе пользования приложениям. Доступны для поиска и добавления только вам.<br/>
      <span class='help-red'>Участники списка</span> - пользователи определенного списка.<br/>
      <span class='help-red'>Контакты</span> - список пользователей, добавленных вами для дальнейшего ведения совместных списков.<br/>
      <span class='help-red'>Расшарить список</span> - поделиться списком через мессенджер (whatsapp или telegram) защищенной ссылкой на список (т.е. будет сгенерирована ссылка
      для дуступа к списку даже без регистрации в приложении).
    </p>
    <p>
      Приложение Listtok предназначено для составления и ведения различных онлайн списков. Например, список покупок, вещей, фильмов и т.д. Имеется возможность
      вести общие (совместные списки) с неограниченным количеством их участников. Это удобно при совместных закупках. Можно создавать неограниченное
      количество личных списков и добавлять в него неограниченное количество товаров. Товары можно отмечать как выполненые, тогда они помечаются серым 
      цветом. Список со всеми выполненными товарами переходит из раздела "Активные" в раздел "Законченые" в пункте главного меню "Списки". 
    </p>
    <p>
      <b>Товары</b> уже есть в системном словаре, который доступен для всех пользователей системы. Если при добавлении
      товара в список окажется, что он отсутствует в системе, то будет предложено добавить данный товар в свой словарь. При добавлении этой
      позиции она автоматически будет добавлена и в список, в котором вы находились, а также в ваш словарь. В дальнейшем эти товары можно
      будет отредактировать, если вы совершили ошибку или же по каким-то другим причинам. Товары с вашего словаря учавствуют в автодополнении при поиске
      товара только в ваших списках.
    </p>
    
    <h2 id="user-manual">Инструкция пользователя</h2>
    <ol class="table-contents">
      <li><a href="#create-list">Создание списка</a></li>
      <li><a href="#product-add">Добавление и удаление товаров в списке</a></li>
      <li><a href="#new-product">Добавление нового товара в свой словарь</a></li>
      <li><a href="#edit-sum">Изменение количества товара и его единицы измерения</a></li>
      <li><a href="#add-link">Добавление ссылки на товар</a></li>
      <li><a href="#checked">Отмечание товара выполненным</a></li>
      <li><a href="#edit-product">Редактирование товаров своего словаря</a></li>
      <li><a href="#contacts">Поиск и добавление контактов</a></li>
      <li><a href="#add-user">Добавление пользователя в свой список</a></li>
      <li><a href="#share">Расшаривание списка через мессенджеры</a></li>
    </ol>
    <h4 id="create-list">1. Создание списка</h4>
    <p>
      Для создания нового списка необходимо перейти в раздел <b>"Списки"</b> главного меню и в вверху страницы в форме создания списка ввести имя, после чего нажать
      кнопку <b>"Создать список"</b> Рис.1.</p>
      <div class="img-help">
        <img src="../assets/help/create.png" width="100%">
        <div class="pic">рис.1</div>
      </div>
    <p>
      После этого на этой же странице ниже появится вновь созданный список среди других (если они имеются) с информацией об его имени, дате создания и изменения.
    </p>

    <h4 id="product-add">2. Добавление и удаление товаров в списке</h4>
    <p>
      Для того, чтобы добавить товары в список необходимо сначала зайти в него. Для этого в разделе <b>"Списки"</b> необходимо нажать на список. При наведении
      курсора на него он подсветится серым светом, как показано на рис.2.
    </p>
    <div class="img-help">
      <img src="../assets/help/go-into-list.png" width="100%">
      <div class="pic">рис.2</div>
    </div>
    <p>После этого вы перейдете на страницу этого списка. Рис.3</p>
    <div class="img-help">
      <img src="../assets/help/empty-list.png" width="100%">
      <div class="pic">рис.3</div>
    </div>
    <p>
      На данном этапе список еще пустой. Для добавления товаров начните вводить в поле ввода интересующий вас товар. По мере ввода снизу поля будут
      предлагаться автодополнением совпадающие варианты товаров, которые уже есть в системе, как показано на рис.4.
    </p>
    <div class="img-help">
      <img src="../assets/help/autocomplete.png" width="100%">
      <div class="pic">рис.4</div>
    </div>
    <p>
      Выпадающий список <b>"фильтр категория"</b>, расположенный правее поля поиска товаров, предназначен для фильтра товаров по категории во время поиска. Он необязателен.
      Т.е., например, если вам нужно, чтобы система предлагала только варианты товаров из категории "Еда", то необходимо выбрать в фильтре значение "Еда",
      после чего вводить в поле поиска нужный вам товар.
    </p>
    <p>
      Если вы по ходу поиска товара в предложенных автодополнением вариантах находите нужный вам, то необходимо выбрать его путем клика мышью, либо тапом (если вы пользуетесь
      приложением с смартфона), либо выбрать нужный вам вариант стрелками на клавиатуре и нажать "Enter". После этого товар будет добавлен в список в
      виде строки в таблице с информацией о его наименовании, дате добавления, количества и единицы измерения (которые можно отредактировать), а также
      переключателя для помечания товара выполненным и кнопки удаления товара из данного списка. Рис 5.
    </p>
    <div class="img-help">
      <img src="../assets/help/product-added.png" width="100%">
      <div class="pic">рис.5</div>
    </div>
    <p>Для удаления товара из списка необходимо нажать на красный значок корзины в строке с нужным товаром.</p>

    <h4 id="new-product">3. Добавление нового товара в свой словарь</h4>
    <p>
      При поиске товара для добавления в список может получиться так, что искомый товар отсутствует в системе. В этом случае в правой области поля поиска
      появится кнопка <b>"Добавить"</b>, как показано на рис.6.
    </p>
    <div class="img-help">
      <img src="../assets/help/add-product.png" width="100%">
      <div class="pic">рис.6</div>
    </div>
    <p>
      Следует отметить, что кнопка <b>"Добавить"</b> также появляется в случае неполного совпадения искомого товара. Например, вам надо добавить товар "лимон",
      которого нет в системе, зато есть товар "лимонад". Пока вы будете вводить букву за буквой кнопка <b>"Добавить"</b> будет активна, т.к. нет полного совпадения
      с имеющимся в системном словаре товаром (или вашем личном словаре).
    </p>
    <p>После нажатия на кнопку <b>"Добавить"</b> откроется диалоговое окно для добавления товара. При этом уже введенное слово в поле поиска до нажатия кнопки
      <b>"Добавить"</b> будет автоматически подставлено в поле с именем товара открывшегося диалогового окна. Необходимо выбрать <b>"категорию"</b> товара, отредактировать
      имя в случае необходимости, а также возможно добавить какие-то детали (например производителя). После этого надо нажать кнопку <b>"Сохранить"</b>. Рис.7
    </p>
    <div class="img-help">
      <img src="../assets/help/add-product-modal.png" width="100%">
      <div class="pic">рис.7</div>
    </div>
    <p>Товар добавится в ваш личный словарь, а также автоматически в список, из которого вы открыли диалоговое окно для добавления нового товара. Рис.8</p>
    <div class="img-help">
      <img src="../assets/help/add-new-product.png" width="100%">
      <div class="pic">рис.8</div>
    </div>
    </br>
    <p>
      Также можно добавить новый товар в свой словарь из пункта главного меню <b>"Мои товары"</b>. Вверху данной страницы предоставлена форма для добавления
      нового товара. Заполнив соответствующие поля и нажав кнопку <b>"Добавить"</b> вы увидите вновь добавленный товар среди других, добаленных ранее (если они были)
      Рис.9.
    </p>
    <div class="img-help">
      <img src="../assets/help/add-new-product2.png" width="100%">
      <div class="pic">рис.9</div>
    </div>

    <h4 id="edit-sum">4. Изменение количества товара и его единицы измерения</h4>
    <p>
      Для изменения количества или единицы изменения товара в списке необходимо находясь в нем нажать на пиктограмму карандаша в столбце "Кол-во" нужного вам товара. Рис.10
    </p>
    <div class="img-help">
      <img src="../assets/help/edit-sum.png" width="100%">
      <div class="pic">рис.10</div>
    </div>
    <p>После нажатия откроется диалоговое окно редактирования нужных нам значений, как показано на рис.11.</p>
    <div class="img-help">
      <img src="../assets/help/edit-sum-modal.png" width="100%">
      <div class="pic">рис.11</div>
    </div>
    <p>Установив нужные значения нажмите кнопку <b>"Сохранить"</b>. Модальное окно закроется, а данные в списке обновятся.</p>

    <h4 id="add-link">5. Добавление ссылки на товар</h4>
    <p>
      Имеется возможность прикреплять ссылки к добавленным в список товарам. Например, вам понадобится указать какой именно товар вам нужен и где его можно купить.
      Для этого нужно при создании нового товара (п.3) или при редактировании
      количества товара и единицы изменения (п.4) нажать на ссылку "Добавить ссылку на товар" или "Добав./изм. ссылку на товар" соответственно. (рис.7 и рис.11)
    </p>
    <p>
      В обоих случаях появится дополнительное поле для ввода ссылки. Вставьте нужную вам ссылку в данное поле и нажмите кнопку <b>"Сохранить'</b>. К товару в этом
      списке будет прикреплена ссылка.
    </p>
    <p class="attention">
      Внимание! Ссылка прикрепляется не к товару как таковому, а к позиции данного товара в списке. Это означает, что в другом списке к этому же товару данная ссылка
      уже прикреплена не будет. Зато можно прикрепить новую другую ссылку или такую же.
    </p>

    <h4 id="checked">6. Отмечание товара выполненным</h4>
    <p>
      Для того, чтобы пометить в списке товар как выполненный, необходимо в столбце с зеленой галочкой в строке нужного вам товара перевести переключатель
      с положения <b>"нет"</b> на <b>"да"</b>. Рис.12.
    </p>
    <div class="img-help">
      <img src="../assets/help/checked.png" width="100%">
      <div class="pic">рис.12</div>
    </div>
    <p>После этих действий отмеченный товар выделится серым цветом и опустится вниз списка, как показано на рис.13.</p>
    <div class="img-help">
      <img src="../assets/help/checked2.png" width="100%">
      <div class="pic">рис.13</div>
    </div>
    <p>
      Отменить это действие можно переводом переключателя обратно в состояние "нет". Товар в списке этом случае вернется в свое исходное положение и он вновь
      станет невыделенным серым.
    </p>
    <p class="attention">
      Если все товары в списке пометить выполненными, то возможности отменить эти действия уже не будет, а сам список перейдет из вкладки "Активные" в "Законченые".
    </p>

    <h4 id="edit-product">7. Редактирование товаров своего словаря</h4>
    <p>
      Для того, чтобы внести изменения в ранее созданый вами товар необходимо перейти в раздел главного меню <b>"Мои товары"</b>. Перед вами будет таблица с товарами,
      которые вы уже добавляли в процессе пользования приложением. Рис. 14.
    </p>
    <div class="img-help">
      <img src="../assets/help/edit-product.png" width="100%">
      <div class="pic">рис.14</div>
    </div>
    <p>Для редактирования нажмите пиктограмму карандаша в строке нужного товара. После нажатия вы сможете отредактировать товар в соответствующих полях. Рис.15.</p>
    <div class="img-help">
      <img src="../assets/help/edit-product2.png" width="100%">
      <div class="pic">рис.15</div>
    </div>
    <p>После внесения правок необходимо нажать на появившуюся около карандаша пиктограмму в виде квадрата со стрелкой внутрь. После ее нажатия данные будут обновлены.</p>

    <h4 id="contacts">8. Поиск и добавление контактов</h4>
    <p>
      Добавление контактов в своем аккаунте необходимо для того, чтобы их в дальнейшем можно было использовать для добавления участников в список.
      Перейдите в раздел главного меню <b>"Мои контакты"</b>. Вы увидите раздел поиска на этой странице и поле ввода для поиска нужного вам пользователя. Рис.16.
    </p>
    <div class="img-help">
      <img src="../assets/help/search-contact.png" width="100%">
      <div class="pic">рис.16</div>
    </div>
    <p>
      Введите никнейм интересующего вас пользователя и нажмите кнопку <b>"Искать"</b>. Если такой пользователь есть в системе, то будут выведены результаты поиска ниже.
      Рис.17.
    </p>
    <div class="img-help">
      <img src="../assets/help/searched-contact.png" width="100%">
      <div class="pic">рис.17</div>
    </div>
    <p>Нажмите на пиктограмму человека со знаком + для того, чтобы добавить найденного пользователя в свой список контактов.</p>

    <h4 id="add-user">9. Добавление пользователя в свой список</h4>
    <p>
      В приложении имеется возможность вести совместные списки. При ведении совместных списков его участники видят изменения в нем в режиме реального времени.
      Для того, чтобы список стал совместным необходимо нaходясь в нужном списке нажать на ссылку <b>"Добавить участника"</b>. После нажатия появится выпадающий список, 
      в котором вы сможете выбрать пользователя, находящегося в списке ваших контактов. Рис.18.
    </p>
    <div class="img-help">
      <img src="../assets/help/add-user.png" width="100%">
      <div class="pic">рис.18</div>
    </div>
    <p>
      После выбора пользователя следует нажать кнопку <b>"Добавить"</b>. После этого данный пользователь становится участником списка. Можно добавлять неограниченное
      количество участников в списках.
    </p>
    <p class="attention">
      Следует отметить, что после расшаривания списка через мессенджеры (п.10), пользователи, которым отправлена ссылка, могут вступить в этот список путем нажатия
      на кнопку <b>"Присоединиться"</b>. Рис.19.
    </p>
    <div class="img-help">
      <img src="../assets/help/join.png" width="100%">
      <div class="pic">рис.19</div>
    </div>

    <h4 id="share">10. Расшаривание списка через мессенджеры</h4>
    <p>
      Можно отправить ссылку на свой список через мессенджеры whatsapp или telegram. Для этого находясь в списке в верхнем правом углу необходимо нажать
      на иллюстрированную кнопку <b>"Поделиться"</b>. Рис.20. 
    </p>
    <div class="img-help">
      <img src="../assets/help/share.png">
      <div class="pic">рис.20</div>
    </div>
    <p>После нажатия появятся кнопки для отправки ссылки через интересующий вас мессенджер: whatsapp или telegram. Рис.21</p>
    <div class="img-help">
      <img src="../assets/help/share2.png">
      <div class="pic">рис.21</div>
    </div>
    <p>
      Нажав соответствующую кнопку вы будете перенаправлены в тот мессенджер, который выбрали для того, чтобы поделиться списком. Далее уже в мессенджере
      выберите нужный контакт для отправки ссылки.
    </p>
    <p class="attention">
      При отправке ссылки формируется защищеннкая ссылка, с обеспечением доступа пользователям, не входящим в данный список, а также незарегистрированным
      в системе пользователям. При этом предоставляются права <b>Участника списка</b> с возможностью отмечать товары как выполненные и добалять новые.<br/>
      При отправке ссылки просто путем копирования ее в строке браузера пользователи, не состоящие в участниках списка, не будут иметь права пользования им
      и просмотра его содержимого.
    </p>
    <p class="attention">
      Эта функция будет работать на смартфоне или на других устройствах в случае наличия на них соответствующих мессенджеров.
    </p>
    
  </div>
</template>

<script>
export default {
	name: 'Help',
	metaInfo() {
    return {
      title: "Помощь | Listtok",
      meta: [
        { name: 'description', content: "Помощь в приложении Listtok. Инструкция пользователя. Часто задаваемые вопросы" },
        { name: 'keywords', content: 'помощь, Listtok, инструкция пользователя, чаво, часто задаваемые вопросы'},
        { vmid: 'og:title', property: 'og:title', content: 'Listok - помощь' },
      ],
    }
  },
}
</script>

