<template>
  <div class="login-wrap">
    <div class="login box">
      <h1>{{voc.h1[lang]}}</h1>
      <small class="form-text text-muted">{{voc.info[lang]}}</small>
      <p></p>

      <form @submit.prevent="onSubmit" @input="inputHandler">
        <div class="form-group">
          <label for="inputLogin">{{voc.email[lang]}}</label>
          <input
            name="email"
            type="text"
            class="form-control"
            id="inputEmail" :placeholder="voc.enterEmail[lang]"
          />
        </div>

        <div class="form-group">
          <label for="inputPassword">{{voc.password[lang]}}</label>
          <input
            name="password_hash"
            :type="[!showPassword ? 'password' : 'text']"
            class="form-control"
            id="inputPassword" :placeholder="voc.password[lang]"
          />
        </div>

        <div class="form-group custom-control custom-checkbox">
          <input
            v-model="showPassword"
            type="checkbox" class="custom-control-input" id="showPassword">
          <label class="custom-control-label" for="showPassword">{{voc.showPassword[lang]}}</label>
        </div>

        <div class="form-group custom-control custom-checkbox">
          <input
            name="rememberMe"
            v-model="loginForm.rememberMe"
            type="checkbox" class="custom-control-input" id="rememberme">
          <label class="custom-control-label" for="rememberme">{{voc.rememberMe[lang]}}</label>
        </div>
        <div class="form-group">
          <button type="submit" class="button" :class="{ hoverDel: !isChecked }" :disabled="!isChecked">{{voc.signIn[lang]}}</button> 
        </div>
        <div class="form-group forget">
            <router-link to="/forget">{{voc.forget[lang]}}</router-link>
        </div>
      </form>
    </div>

    <div class="noaccount box">
      <span>{{voc.noaccount[lang]}}</span>
      <router-link to='/signup' class="noaccreg">{{voc.register[lang]}}</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueAxios from 'vue-axios'

import translations from './translations';

	export default {
		name: 'Login',
    metaInfo() {
      return {
        title: this.voc.title[this.lang],
        meta: [
          { vmid: 'description', property: 'description', content: this.voc.description[this.lang] },
          { vmid: 'og:title', property: 'og:title', content: 'Listtok' },
          { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
        ],
      }
    },
    data() {
      return {
        model: {
          email:{
            value:'',
            //regexp:/^[\w\.-]{4,20}@{1}\w+\.[\w\s]+$/,
            regexp:/^[a-zа-я0-9\w\.\@\-_]{2,128}$/i,
            isChecked:false,
          },
          password_hash:{
            value:'',
            regexp:/^.{6,20}$/,
            isChecked:false,
          },
        },
        loginForm:{
          email: '',
          password_hash: '',
          rememberMe: true
        },
        voc: translations,
        showPassword: false,
      }
    },
    computed:{
      lang(){
        return this.$store.getters.lang;
      },
      isChecked(){
        for (let key in this.model)
        {
          if (!this.model[key].isChecked)
            return false;
        }
        return true;
      },
    },
    methods:{
      setValue(el, field, value)
      {
        const COLOR={
          red:'#FFB6C1',
          green:'#B4EEB4'
        };

        if ( this.model[field].regexp.test(value) )
        {
          this.model[field].value = value;
          this.model[field].isChecked = true;
          el.style.backgroundColor=COLOR.green;
        }
        else{
          this.model[field].isChecked = false;
          el.style.backgroundColor=COLOR.red;
        }

      },

      inputHandler(e){
        let el = e.target;

        if (this.model[el.name] && this.model[el.name].regexp)
        {
          this.setValue(el, el.name, el.value.trim());
        }

      },

      onSubmit: function(e){
        this.loginForm.email = this.model.email.value.toLowerCase().trim();
        this.loginForm.password_hash = this.model.password_hash.value;
        // console.log(axios.defaults.headers.common);
        axios.post('/login', this.loginForm)
          .then(response => {
            this.$store.dispatch('getMenu');
            this.$router.push('/');
          })
          .catch(e => {
            this.$store.dispatch('setError', e);
          });

      }
    },
  }
</script>
