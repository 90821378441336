<template>
  <div>
  <span class='plusminus' id='minus' @click='minus'>-</span>
  <span>
    <input
      type="text"
      :id="'sum_'+skey"
      class="edit_sum"
      v-model=sum
      :size="1"
    />
  </span>
    <span class='plusminus' id='plus' @click="plus">+</span>
  </div>
</template>

<script>
export default {
  name: 'itemSum',
  props: {
    skey: Number,
  },
  data(){
    return {
      sum: 1,
    }
  },
  methods: {
    plus() {
      this.sum++;
      //this.$emit('sum-change', {data: this.sum})
    },
    minus() {
      if(this.sum>1) this.sum--;
      //this.$emit('sum-change', {data: this.sum})
    },
  },
}
</script>
