<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
                <div class='name_edit'>{{name}}</div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <span>{{voc.qty[lang]}}:</span>
              <span class='plusminus' id='minus' @click='minus'>-</span>
              <input type="text" id="edit_sum"
                v-model="model.sum.value"
                :size="5"
              />
              <span class='plusminus' id='plus' @click="plus">+</span>

              <select id="sel_edit" v-model="model.unit.value">
                <option v-for="(uitem, index) in units" :selected="item.unit==uitem.id" :value="uitem.id">{{uitem[lang]}}</option>
              </select>
            </slot>
          </div>

          <div class="form-group">
            <div class="add-link">
              <a href='#' @click.prevent="showAddLinkHandler">{{voc.addLink[lang]}}</a>
            </div>
            <div class="form-group" v-if="showLinkAdd">
              <label for="link">{{voc.link[lang]}}:</label>
              <input v-model="model.link.value" type="text" class="form-control" id="link" name="link" :placeholder="voc.linkPlaceholder[lang]">
            </div>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-success" @click="saveHandler">
                {{voc.save[lang]}}
              </button>
              <button class="btn btn-primary" @click="$emit('close')">
                {{voc.cancel[lang]}}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import translations from './translations';

export default {
  name: 'Modal',
  props:{
    item: Object,
    lang: String,
    units: Object,
  },
  data(){
    return {
      model: {
        sum: {
          value: this.item.sum,
        },
        unit: {
          value: this.item.unit,
        },
        link: {
          value: this.item.link,
        },
      },
      showLinkAdd: false,
      voc: translations,
    }
  },
  computed: {
    name(){
      if(this.lang=='ru'){
        return this.item.name_ru
      }else return this.item.name_en
    },

  },
  methods: {
    saveHandler(){
      // console.log(this.model.sum.value)
      // console.log(this.model.unit.value)
      // console.log(this.item.iid)
      let iid = parseInt(this.item.iid);
      let sum = parseInt(this.model.sum.value);
      let unit = parseInt(this.model.unit.value);
      let link = this.model.link.value == '' ? null : this.model.link.value;
      this.$emit('save', {iid: iid, sum: sum, unit: unit, link: link});
    },
    plus() {
      this.model.sum.value++;
      //this.$emit('sum-change', {data: this.sum})
    },
    minus() {
      if(this.model.sum.value>1) this.model.sum.value--;
      //this.$emit('sum-change', {data: this.sum})
    },
    showAddLinkHandler(){
      this.showLinkAdd = !this.showLinkAdd;
    },
  },
}
</script>
