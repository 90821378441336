export default {
	title: {
		en: 'Password recovery | Listtok',
		ru: 'Восстановление пароля | Listtok'
	},
	description: {
    en: 'Password recovery in the online application Listtok',
    ru: 'Восстановление пароля в онлайн приложении Listtok',
  },
  ogdescription: {
    en: 'Password recovery',
    ru: 'Восстановление пароля'
  },
	h1: {
		en: 'Password recovery',
		ru: 'Восстановление пароля'
	},
	email: {
		en: 'Email address',
		ru: 'Email адрес'
	},
	enterEmail: {
		en: 'Enter email',
		ru: 'Введите email'
	},
	smallText: {
		en: 'Instructions for password recovery will be sent to the specified email address.',
		ru: 'На указанный адрес электронной почты будет выслана инструкция по восстановлению пароля.'
	},
	send: {
		en: 'Send',
		ru: "Выслать"
	},
	h3: {
		en: 'Sended',
		ru: 'Отправлено'
	},
	sended: {
		en: 'Password recovery instructions have been successfully sent to the specified email address.',
		ru: 'Инструкция по восстановлению пароля успешно отправлена на указанный адрес электронной почты.'
	},
	toFront: {
		en: 'To the front',
		ru: 'На главную'
	},
}