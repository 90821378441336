<template>
  <div class="contacts containerBox">
    <h1>{{voc.myContacts[lang]}}</h1>
    <div>
      <div class="row">
        <div class="col-sm">
          <h2>{{voc.contactList[lang]}}</h2>
          <ul class="list-group text-left" v-if="contacts">
            <li class="list-group-item" v-for="(item, index) in contacts" :uid="item.id">
              {{item.username}}
              <a @click.stop.prevent='delContactHandler' href='#'>
                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-person-x add-contact" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10zm1.146-7.85a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-sm">
          <h2>{{voc.search[lang]}}</h2>
          <div class="input-group mb-3">
            <input
              v-model='searchValue'
              class='form-control'
              type='text'
              name='username'
              :placeholder="voc.enterNickname[lang]"
              @keypress.enter.stop.prevent='searchHandler'
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" @click='searchHandler'>{{voc.buttonSearch[lang]}}</button>
            </div>
          </div>

          <div v-if="user.username">
            <h3>{{voc.resultSearch[lang]}}</h3>
            <div class='result' :uid='user.id'>
              <b>{{user.username}}</b>
              <span class='add-contact'>
                <a @click.stop.prevent='addContactHandler' href='#'>
                  <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-person-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10zM13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                  </svg>
                </a>
              </span>
            </div>
          </div>
          <div v-if='user.empty' class='warn'>{{user.empty}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translations from './translations';
import axios from 'axios';
import VueAxios from 'vue-axios';

export default {
  name: 'MyContacts',
  metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        { vmid: 'description', property: 'description', content: this.voc.description[this.lang] },
        { vmid: 'og:title', property: 'og:title', content: 'Listtok' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
  data(){
    return{
      searchValue: '',
      user: {},
      contacts: [],
      voc: translations,
    }
  },
  computed: {
    lang(){
      return this.$store.getters.lang;
    },
  },
  methods: {
    searchHandler(){
      axios.get('/contact/search', {params:
                                      {username: this.searchValue}
                                    })
      .then(response=>{
        this.user=response.data;
        console.log(response.data)
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    addContactHandler(e){
      let link = e.currentTarget.parentNode.parentNode
      let id = link.getAttribute('uid');
      console.log(id)
      axios.get('/contact/add', {params:
                                  {contact_id: id}
                                })
      .then(()=>{
        //console.log('contact added')
        this.getContacts();
        this.user = {};
        this.searchValue = '';
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    delContactHandler(e){
      let link = e.currentTarget.parentNode;
      let id = link.getAttribute('uid');
      console.log(id)
      axios.get('/contact/del', {params:
                                  {contact_id: id}
                                })
      .then(()=>{
        console.log('contact deleted');
        this.getContacts();
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
    getContacts(){
      axios.get('/contact/list')
      .then(response=>{
        this.contacts = response.data;
        console.log(this.contacts)
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
  },
  created(){
    this.getContacts();
  },
}
</script>
