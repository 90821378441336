export default {
  products: {
    en: 'Products',
    ru: 'Товары'
  },
  nameProductRu: {
    en: 'Name ru',
    ru: 'Наименование рус'
  },
  nameProductEn: {
    en: 'Name en',
    ru: 'Наименование англ'
  },
  details: {
    en: 'Details (optional)',
    ru: 'Детали (необяз.)',
  },
  saveProduct: {
    en: "Add Product",
    ru: 'Добавить товар'
  },
  chooseCat: {
    en: 'choose category',
    ru: 'выберите категорию'
  },
  tableColumns: {
    name_ru: {
      en: 'Name ru',
      ru: 'Наименование рус'
    },
    name_en: {
      en: 'Name en',
      ru: 'Наименование aнгл'
    },
    category: {
      en: 'Category',
      ru: 'Категория'
    },
    owner: {
      en: 'Owner',
      ru: 'Владелец'
    },
  },
  title: {
    en: 'Product list',
    ru: 'Список товаров'
  },
  description: {
    en: 'List of goods. Adding new products.',
    ru: 'Список товаров. Добавление новых товаров.',
  },
  ogdescription: {
    en: 'Products list',
    ru: 'Список товаров'
  },
}
