<template>
  <div class="scroll-table">
    <table class="table products-table">
      <thead class="thead-dark">
        <tr>
          <th scope="row">#</th>
          <th scope="row" v-for="(item, key) in tableColumns">
            {{item[lang]}}
          </th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="(product, index) in model" :iid="product.id">
        <td>{{ currentPage*20-20+(index+1)}}</td>
        <td class="word-break">
          <span v-if="!editable || editableId!=product.id">{{product.name_ru || product.name_en}}</span>
          <span v-if="editable && editableId==product.id">
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              v-model="editModel.name.value"
            />
          </span>
        </td>
        <td v-if="lang == 'ru'">
          <span v-if="!editable || editableId!=product.id">{{product.cat_ru}}</span>
          <select v-if="editable && editableId==product.id" name="category" id='category-edit' class="form-control">
            <option v-for="category in categories" :selected="product.category==category.id" :value="category.id">{{category[lang]}}</option>
          </select>
        </td>
        <td v-if="lang == 'en'">
          <span v-if="!editable || editableId!=product.id">{{product.cat_en}}</span>
          <select v-if="editable && editableId==product.id" name="category" id='category-edit' class="form-control">
            <option v-for="category in categories" :selected="product.category==category.id" :value="category.id">{{category[lang]}}</option>
          </select>
        </td>
        <td>
          <svg @click.stop="editHandler" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil edit-button" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>
          <svg v-if="editable && editableId==product.id" @click.stop="saveHandler" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-save save-button" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
          </svg>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "VocabularyTable",
  props: {
    tableColumns: Object,
    model: Array,
    lang: String,
    currentPage: Number,
    categories: Array,
  },
  data() {
    return {
      editable: false,
      editableId: '',
      editModel: {
        name: {
          value: '',
          regexp: /^[а-яё\d\s-]{1,256}$/i,
          isChecked: false,
        },
        category: {
          value: '',
          regexp:/^\d+$/,
          isChecked:false
        },
      },
    }
  },
  methods: {
    editHandler(e){
      this.editableChange()
      let target = e.currentTarget.parentNode.parentNode;
      let id = target.getAttribute('iid');
      this.editableId = id;
      this.editModel.name.value = target.firstChild.nextElementSibling.textContent;
      // console.log(this.editModel);
    },
    async saveHandler(e){
      let target = e.currentTarget.parentNode.parentNode;
      let id = target.getAttribute('iid');
      this.editModel.category.value = document.getElementById('category-edit')[document.getElementById('category-edit').selectedIndex].value
      // console.log(this.editModel);

      let formData = new FormData();
      formData.append('id', id);
      formData.append('name', this.editModel.name.value);
      formData.append('category', this.editModel.category.value);
      formData.append('lang', this.lang);
      axios.post('products/update-own', formData)
      .then(()=>{
        console.log('data update')
        this.editableChange()
        this.$emit('get-after-update')
      })
    },
    editableChange(){
      this.editable = !this.editable;
    },
  },
}
</script>
