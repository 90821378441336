export default {
    title: {
      ru: 'Пользователи | Listtok',
      en: 'Users | Listtok'
    },
    description: {
      en: 'All Users',
      ru: 'Все пользователи'
    },
    ogdescription: {
      en: 'All Users',
      ru: 'Все пользователи'
    },
    h1: {
      ru: 'Пользователи',
      en: 'Users'
    },
    tableColumns: {
      user: {
        ru: 'Пользователь',
        en: 'User'
      },
      created: {
        ru: 'Создан',
        en: 'Created'
      },
      updated: {
        ru: 'Обновлен',
        en: 'Updated'
      },
    }
}