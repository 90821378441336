<template>
  <div class="login-wrap">
    <div class="login box">
      <h1>{{voc.signup[lang]}}</h1>
      <small class="form-text text-muted">{{voc.description[lang]}}</small>
      <p></p>

      <form @submit.prevent="onSubmit" @input="inputHandler">
        <div class="form-group">
          <label for="inputEmail">{{voc.email[lang]}}</label>
          <input
            name="email"
            type="text"
            class="form-control"
            id="inputEmail" :placeholder="voc.enterEmail[lang]"
          />
        </div>

        <div class="form-group">
          <label for="inputName">{{voc.nickname[lang]}}</label>
          <input
            name="username"
            type="text"
            class="form-control"
            id="inputName" :placeholder="voc.enterNickname[lang]"
          />
        </div>

        <div class="form-group">
          <label for="inputPassword1">{{voc.password[lang]}}  ({{voc.sixchars[lang]}})</label>
          <input
            name="password_hash"
            :type="[!showPassword ? 'password' : 'text']"
            class="form-control"
            id="inputPassword1" :placeholder="voc.password[lang]"
          />
        </div>

        <div class="form-group">
          <label for="inputPassword2">{{voc.password2[lang]}}</label>
          <input
            name="password_hash_repeat"
            :type="[!showPassword ? 'password' : 'text']"
            class="form-control"
            id="inputPassword2" :placeholder="voc.password[lang]"
          />
          <p class='warn' v-if="showWrong">{{voc.pass2wrong[lang]}}</p>
        </div>

        <div class="form-group custom-control custom-checkbox">
          <input
            v-model="showPassword"
            type="checkbox" class="custom-control-input" id="showPassword">
          <label class="custom-control-label" for="showPassword">{{voc.showPassword[lang]}}</label>
        </div>

        <button type="submit" class="button" :class="{ hoverDel: !isChecked }" :disabled="!isChecked">{{voc.signUping[lang]}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueAxios from 'vue-axios'
import translations from './translations';

export default{
  name: 'Signup',
  metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        { vmid: 'description', property: 'description', content: this.voc.description[this.lang] },
        { vmid: 'og:title', property: 'og:title', content: 'Listtok' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
  data(){
    return{
      model: {
        email: {
          value: '',
          regexp: /^[\w\.-]{2,20}@{1}\w+\.[\w\s]+$/,
          isChecked:false,
        },
        username: {
          value: '',
          regexp: /^[a-zа-я0-9_-]{2,128}$/i,
          isChecked: false,
        },
        password_hash:{
          value:'',
          regexp:/^.{6,20}$/,
          isChecked:false,
        },
        password_hash_repeat:{
          value:'',
          regexp:/^.{6,20}$/,
          isChecked:false,
        },
      },
      SignupForm: {
        email: '',
        username: '',
        password: '',
        retypePassword: ''
      },
      voc: translations,
      showPassword: false,
      showWrong: false,
    }
  },
  computed:{
    lang(){
      return this.$store.getters.lang;
    },
    isChecked(){
      for (let key in this.model)
      {
        if (!this.model[key].isChecked)
          return false;
      }
      if(this.model.password_hash_repeat.value){
        if(this.model.password_hash.value != this.model.password_hash_repeat.value) return false;
      }
      return true;
    },
  },
  methods:{
    setValue(el, field, value)
    {
      const COLOR={
        red:'#FFB6C1',
        green:'#B4EEB4'
      };

      if ( this.model[field].regexp.test(value) )
      {
        this.model[field].value = value;
        this.model[field].isChecked = true;
        el.style.backgroundColor=COLOR.green;

        if(field=='password_hash_repeat' && this.model[field].value != this.model['password_hash'].value){
            this.model[field].isChecked = false;
            el.style.backgroundColor=COLOR.red;
            this.showWrong = true;
          }else this.showWrong = false;
      }
      else{
        this.model[field].isChecked = false;
        el.style.backgroundColor=COLOR.red;
      }
    },
    inputHandler(e){
      let el = e.target;

      if (this.model[el.name] && this.model[el.name].regexp)
      {
        this.setValue(el, el.name, el.value.trim());
        //console.log(this.model)
      }
    },
    onSubmit: function(e){
      this.SignupForm.email = this.model.email.value.toLowerCase().trim();
      this.SignupForm.username = this.model.username.value;
      this.SignupForm.password = this.model.password_hash.value;
      this.SignupForm.retypePassword = this.model.password_hash.value;

      // console.log(axios.defaults.headers.common);
      axios.post('/signup', this.SignupForm)
        .then(response => {
          this.$store.dispatch('getMenu');
          this.$router.push('/');
          this.$store.dispatch('setMessage',
          {data: `${this.voc.sucReg[this.lang]}`,
          color: 'green'})
        })
        .catch(e => {
          this.$store.dispatch('setError', e);
        });
    },
  },
}
</script>
