export default {
	/*ru:{
		title:'Вход в систему',
		description:'Для входа в систему заполните следующие поля:',
	},
	en:{
		title:'Sign in',
		description:'To enter the system, fill in the following fields:',
	},*/

		h1:{
			ru:'Вход в систему',
			en:'Sign in',
		},
		title: {
			ru: 'Вход | Listtok',
			en: 'Login | Listtok'
		},
		info:{
			ru:'Для входа в систему заполните следующие поля:',
			en:'To enter the system, fill in the following fields:',
		},
		description: {
			ru: 'Вход в онлайн приложение Listtok',
			en: 'Ligin into online app Listtok'
		},
		ogdescription: {
	    ru: 'Вход в приложение Listtok',
	    en: 'Login into app Listtok'
	  },
		email:{
			ru:'Email или Имя',
			en:'Email or Name'
		},
		password:{
			ru:'Пароль',
			en:'Password'
		},
		rememberMe:{
			ru:'Запомнить меня',
			en:'Remember me'
		},
		signIn:{
			ru:'Войти в систему',
			en:'Sign in'
		},
		enterEmail:{
			ru:'Введите email или имя',
			en:'Enter email or name'
		},
		showPassword:{
			ru:'Показать пароль',
			en:'Show password'
		},
		forget:{
			ru: 'Забыли пароль?',
			en: 'Forget password?'
		},
		noaccount:{
			ru: 'Ещё нет аккаунта?',
			en: 'There is no account yet?'
		},
		register: {
			ru: 'Зарегистрируйтесь',
			en: 'Register'
		}
}
